import axios from 'axios'
import {Cookies} from "react-cookie";
import mainJson from '../../node_modules/@clout-team/web-components/package.json';
const cookies = new Cookies();

const checkResponse = (response: any): any => response.data
const catchError = (error: any): string => error

export const wrapper = async (method: any, url: any, data: any, token?: string) => {
  axios.defaults.headers.common.lang = cookies.get('lang') ?? 'ru'
  if (token) axios.defaults.headers.common.token = token;
  if (mainJson) axios.defaults.headers.common.version = mainJson.apiVersion;
  return await axios.request({ method, url, data }).then(checkResponse).catch(catchError)
}

export const requestWithoutAuth = async (method: any, url: any, data: any, token: string) => {
  axios.defaults.headers.common.lang = cookies.get('lang') ?? 'ru'
  if (mainJson) axios.defaults.headers.common.version = mainJson.apiVersion;
  return await axios.request({
    method,
    url,
    data,
    headers: { token }
  }).then(checkResponse).catch(catchError)
}

export const requestSync = (method: string = 'GET', url: string, tokenStr = '') => {
  let object = ''
  const xhr = new XMLHttpRequest();
  xhr.open(method, url, false);
  if (tokenStr) {
    xhr.setRequestHeader('token', tokenStr);
  }

  xhr.setRequestHeader('lang', cookies.get('lang') ?? 'ru');
  xhr.setRequestHeader('version', mainJson.apiVersion);
  const str = new FormData();
  xhr.onreadystatechange = function (e: any) {

    if (e.target.readyState === 4) {
      if (e.target.status === 200 || e.target.status === 400) {
        try {
          object = JSON.parse(e.target.response);
        } catch (e) {
          console.log('e', e);
        }
      }
    }
  }
  xhr.send(str);
  return object;
}
