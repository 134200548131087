import React, {useEffect, useState} from 'react'
import Input from '../Input/Input'
import "./Login.scss"
import {ReactComponent as LenzaIcon} from '../../assets/images/lenzaLogo.svg'
import Heading from '../Heading/Heading'
import {useDispatch, useSelector} from 'react-redux'
import {ILoginSlice, setIsLoading, setMail} from '../../state/slices/login'
import {requestCodeByEmail} from '../../api/api'
import {useAppSelector} from '../../api/hooks'
import {Button,} from "@clout-team/web-components";
import Privacy from "../Privacy/Privacy";
import { isMobile, isValidEmail } from '@clout-team/helpers'
import {trimPaste} from "../../helpers/functions";

const Login = () => {
  const dispatch = useDispatch()
  const error = useAppSelector((state) => (state.login as ILoginSlice).error)
  const isLoading = useAppSelector((state) => (state.login as ILoginSlice).is_loading)
  const [value, setValue] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''

  useEffect(() => {
    const input = document.getElementById('email-input');
    if (input && !isMobile()) setTimeout(() => {
      input.focus();
    }, 500)
  }, [])

  const onPasteHandle = (e: any) => {
    trimPaste(e)
    setTimeout(() => {
      onChangeHandler(e)
    }, 100)
  }

  const onChangeHandler = (e: any) => {
    const value = e.target.value.trim()
    setIsError(false)
    setValue(value)
    if (isValidEmail(value)) {
      setIsValid(true)
      return
    }
    setIsValid(false)
  }

  const onBlurHandler = () => {
    if (isValid || value?.trim()?.length === 0) {
      setIsError(false)
      return
    }
    setIsError(true)
  }

  const onContinueHandler = () => {
    if (isValidEmail(value) && isValid && !isError) {
      dispatch(setIsLoading(true))
      dispatch(setMail(value))
      dispatch(requestCodeByEmail({email: value}))
    } else {
      setIsError(true)
    }
  }

  const onKeyDownHandler = (e: any) => {
    if (e.code === 'Enter') {
      onContinueHandler()
    }
  }

  return (
    <div className={'log_login'}>
      <LenzaIcon className={'log_login__logo'}/>
      <Heading title={L('auth.new.welcome')} description={L('auth.new.main.description')}/>
      <div className={'log_login__bottom_container'}>
        <Input
          onPaste={onPasteHandle}
          id={'email-input'}
          title={L('auth.new.main.email_address')}
          className={'log_login__input'}
          fullWidth
          placeholder={L('auth.new.main.enter_email')}
          value={value?.trim()}
          errorMessage={isError ? L('auth.new.main.error') : ''}
          onChange={onChangeHandler}
          onBlurHandler={onBlurHandler}
          onKeyDown={onKeyDownHandler}
          type={'email'}
          focus={true}
        />
        {error !== null && <p className={'log_login__error'}>{L(error)}</p>}
        <Button
          loading={isLoading}
          size={'lg'}
          disabled={isError || value === ''}
          fullWidth
          onClick={onContinueHandler}
        >{L('auth.new.main.continue_btn')}</Button>
      </div>
      <Privacy/>
    </div>
  )
}

export default Login
