import { IS_LOCALHOST, REFERRERS } from './constants'
import { isElectron } from '@clout-team/helpers/dist/utils/web-utils'
import { Cookies } from 'react-cookie'
import { sendToElectron } from '@clout-team/web-components'
import { isIosApp } from '@clout-team/web-components'
import { isAndroidApp } from '@clout-team/web-components'
import { config } from '@clout-team/web-components'

const cookies = new Cookies()

export const chatURL = (company = '', token?: string) => {
  if (config.IS_DEVELOP) {
    return `https://dev-chat.lenzaos.com/${company}?t=${token}`
  }

  if (config.IS_STABLE) {
    return `https://stable-chat.lenzaos.com/${company}?t=${token}`
  }

  return `${process.env.REACT_APP_CHAT_URL}${company}?t=${token}`;
}

export const spaceURL = () => `${process.env.REACT_APP_SPACE_URL}`
export const meetURL = () => `${process.env.REACT_APP_MEET_URL}`
export const contactsURL = () => `${process.env.REACT_APP_CONTACTS_URL}`

export const referrerRedirect = (referrer: string, company?: string, token: string = cookies.get('token')) => {
  if (referrer === REFERRERS.CHATS) {
    if (isElectron() && window.appAddAccount) {
      sendToElectron('add-view-account', { token: token })
      return
    }

    if ((isIosApp() || isAndroidApp()) && window.appAddMobile) {
      // if (isMobile() && window.appAddMobile) {
      window?.top?.postMessage({ type: 'add-view-account', token }, '*')
      return
    }

    if (IS_LOCALHOST) {
      document.location.href = `http://localhost:4001/${company}?t=${token}`
    } else {
      document.location.href = chatURL(company, token)
    }

  }
}
