import * as lg from '../../assets/locate/index'
import { Cookies } from 'react-cookie'
import {setCookie} from "../../helpers/functions";
import {getUrlParamByKey} from "@clout-team/helpers/dist/utils/web-utils";
import {LANG_ARR} from "../../helpers/constants";
const cookies = new Cookies()

const _lg: any = {}
let userLang = 'en', urlLang = ''
Object.keys(lg).forEach((key: any) => {
  if (navigator.language && navigator.language.indexOf(key) > -1) userLang = key
  _lg[key] = (lg as any)[key]
})

const urlLangArr = document.location.pathname.split('/');
if (urlLangArr[1] && LANG_ARR.includes(urlLangArr[1])) urlLang = urlLangArr[1];
const languageCookie = cookies.get('lang')
const paramLang = getUrlParamByKey('lang'); // для приглашения

// priority
if (paramLang && LANG_ARR.indexOf(paramLang) > -1) userLang = paramLang; // 1 invite lang
else if (urlLang !== '' && LANG_ARR.indexOf(urlLang) > -1) userLang = urlLang; // 2 URL lang
else if (languageCookie && LANG_ARR.indexOf(languageCookie) > -1) userLang = languageCookie; // 3 cookie lang

setCookie('lang', userLang)

const localization = (
  state = {
    locale: userLang,
    translations: _lg,
  },
  action: any,
) => {
  switch (action.type) {
    case 'SET_LOCALE': {
      setCookie('lang', action.locale)
      return {
        translations: _lg,
        locale: action.locale,
      }
    }
    default:
      return state
  }
}

export default localization
