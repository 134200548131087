import React, { ReactElement, useEffect } from 'react'
import Main from './components/Main/Main'
import { Navigate, Route, Routes } from 'react-router-dom'
import InviteInvalid from './components/InviteInvalid/InviteInvalid'
import { Cookies } from 'react-cookie'
import { isElectron } from '@clout-team/helpers/dist/utils/web-utils'
import { getOs } from './invorkers'
import { setOs } from './state/slices/ui'
import { useDispatch } from 'react-redux'
import { ApplicationStorage, LangSystem } from '@clout-team/web-components'
import { COLOR_MODE_COOKIE_KEY, THEME_COOKIE_KEY } from './helpers/constants'
import { initColorMode, initTheme } from './helpers/functions'

const cookies = new Cookies()

const App = (): ReactElement => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isElectron()) {
      getOs().then((res: string) => dispatch(setOs(res)))
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const lang = cookies.get('lang')
      LangSystem.changeLang(lang)
    }, 100)

    const onChangeMessage = (event: MessageEvent<{type: string, value: string}>) => {
      try {
        if (event.data.type === 'change-color-mode') {
          initColorMode(event.data.value)
        }

        if (event.data.type === 'change-theme') {
          initTheme(event.data.value)
        }
      } catch (e) {
        console.log(e)
      }
    }

    window.addEventListener('message', onChangeMessage);

    return () => {
      window.removeEventListener('message', onChangeMessage)
    }
  }, [])


  const getLang = () => {
    const lang = cookies.get('lang')
    const getParams = document.location.search
    if (getParams) return `/${lang + getParams}`
    return `/${lang}`
  }

  return (<ApplicationStorage>
      <Routes>
        <Route path='/:locale/:path' element={<Main />} />
        <Route path='/:locale' element={<Main />} />
        <Route path='/' element={<Navigate to={getLang()} />} />
        <Route path='*' element={<InviteInvalid />} />
      </Routes>
    </ApplicationStorage>)
}

export default App
