export interface ILangList {
  title: string
  image: any
  reduction: string
  currentKeyName: string
}

export const langList: Array<ILangList> = [
  { currentKeyName: 'auth.label.united_states', reduction: 'en', image: null, title: 'English (currentName)' },
  { currentKeyName: 'auth.label.united_kingdom', reduction: 'enGB', image: null, title: 'English (currentName)' },
  { currentKeyName: 'auth.label.germany', reduction: 'de', image: null, title: 'Deutsch (currentName)' },
  { currentKeyName: 'auth.label.spain', reduction: 'es', image: null, title: 'Español (currentName)' },
  { currentKeyName: 'auth.label.france', reduction: 'fr', image: null, title: 'Français (currentName)' },
  { currentKeyName: 'auth.label.italy', reduction: 'it', image: null, title: 'Italiano (currentName)' },
  { currentKeyName: 'auth.label.brasil', reduction: 'pt', image: null, title: 'Português (currentName)' },
  { currentKeyName: 'auth.label.russia', reduction: 'ru', image: null, title: 'Русский (currentName)' },
  { currentKeyName: 'auth.label.united_arab_emirates', reduction: 'ar', image: null, title: 'العربية (currentName)' },
  { currentKeyName: 'auth.label.japan', reduction: 'ja', image: null, title: '日本語 (currentName)' },
  { currentKeyName: 'auth.label.china', reduction: 'cn', image: null, title: '繁體中文 (currentName)' },
  { currentKeyName: 'auth.label.korea', reduction: 'ko', image: null, title: '한국어 (currentName)' },
  { currentKeyName: 'auth.label.india', reduction: 'hi', image: null, title: 'हिन्दी (currentName)' }
]

export const useLangList = (): Array<{
  title: string
  image: any
  reduction: string
  currentKeyName: string
}> => langList
