import React, {useState} from 'react';
import {Text} from "@clout-team/web-components";
import {useSelector} from "react-redux";
import DialogAgreement from "../DialogAgreement/DialogAgreement";
import DialogPrivacy from "../DialogPrivacy/DialogPrivacy";
import "./Privacy.scss";

const Privacy = () => {

  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || '',
    [dialogPrivacy, setDialogPrivacy] = useState(false),
    [dialogAgree, setDialogAgree] = useState(false)



  return (
    <div className={'auth-privacy'}>
      {dialogAgree && <DialogAgreement L={L} onClose={() => setDialogAgree(false)}/>}
      {dialogPrivacy && <DialogPrivacy L={L} onClose={() => setDialogPrivacy(false)}/>}
      <Text
        align={"center"}
        className={"text-privacy"}
        type={"secondary"}
        size={"footnote"}
      >
        {L("auth.privacy.start")}{" "}
        <span className={'auth-privacy-link'} onClick={() => setDialogAgree(true)}>
          {L("auth.privacy.user.agree")}{" "}
        </span>{" "}
        {L("auth.privacy.and.the")}{" "}
        <span className={'auth-privacy-link'} onClick={() => setDialogPrivacy(true)}>
          {L("auth.privacy.user.privacy")}
        </span>
      </Text>
    </div>
  );
};

export default Privacy;
