import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import {
  checkDomainIsValid,
  confirmCodeByEmail,
  createInviteLink,
  createNewAccount,
  fillProfileInformation, getHashcodeInvite,
  getPublicDomainInfo,
  getWorkspaceToken,
  inviteLinkInfo,
  inviteUsers,
  requestCodeByEmail,
  sendMessageAndAddToContacts,
  skipInviteUsers,
  userFalseInviteFillProfile,
  userTrueInviteFillProfile,
} from '../../api/api'
import {
  getReferrer,
  setCookieAccountToken
} from "../../helpers/functions";
import {CODE_ERRORS, REFERRERS} from "../../helpers/constants";
import {referrerRedirect} from "../../helpers/urls";
import * as Sentry from "@sentry/browser";
import { getCookie } from '@clout-team/helpers'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export type steps =
  | 'START'
  | 'CODE'
  | 'WORKSPACE'
  | 'PROFILE'
  | 'FINISH'
  | 'DOMAIN'
  | 'LOADING'
  | 'ERROR'
  | 'INVITE_USERS'
  | 'JOIN_COMPANY'
  | 'INVITE_INVALID'
  | 'COMPANY_BANNER'
  | 'ADD_FRIENDS'
  | 'JOIN_DOMAIN'

export interface Profile {
  hash: string
  name: string
  surname: string
  login: string
  domain: string
  avatar: string
  status: 'ACTIVE' | 'INVITED'
  hash_invite?: string
}

export interface IFriend {
  id: string
  hash: string
  login: string
  domain: string
  phone: string
  name: string
  surname: string
  email: string
  avatar_full: string
  avatar: string
  country: string | null
  city: null | string
  timezone: string
  time_12_hour: boolean
  timezone_manual: boolean
  company_id: number
  about: string
  job_position: string
  birthday: null | string
  social_networks: any
  last_online: number
  updated_at: number
  department_id: number[]
  department: { id: number; title: string }
  subject_id: number | null
  is_friend: boolean
  is_friend_request: boolean
  is_friend_blocked: boolean
  is_delete: boolean
  is_blocked: boolean
  status: string
  role: string
  is_you: boolean
  contact_type: string
  contact_status: string
}

export interface ILoginSlice {
  mail: string | null
  user_hash: string | null
  hash_code: string | null
  name: string | null
  surname: string | null
  token: string | null
  step: steps

  error: string | null
  company_name: string | undefined
  profile: Profile[] | []
  hash_registration: string | undefined
  is_loading: boolean
  is_domain_loading: boolean
  is_domain_free: boolean | null
  is_loading_friends: boolean
  avatar_b64: string | null
  avatar_id: number | null
  invite_link: string | null
  company_avatar: undefined | string
  invite_hash: null | string
  friends: IFriend[] | []
  is_domain_invite: boolean
  is_my_domain_corp: boolean
  domains: string[] | []
  info_domains: any[]
  allowed_domains: any
  invite_profiles: any
  type: 'LOGIN' | 'REG' | 'INVITE_LINK' | 'INVITE_MAIL' | 'INVITE_DOMAIN'
  workspaceUserHash: null | string
  referrer: 'chat' | 'space' | undefined
}

const initialState = {
  mail: null,
  user_hash: null,
  hash_code: null,
  name: null,
  surname: null,
  token: null,
  step: 'LOADING',
  error: null,
  company_name: undefined, //domain
  profile: [],
  hash_registration: undefined,
  is_loading: false,
  is_domain_loading: false,
  is_domain_free: null,
  is_loading_friends: true,
  avatar_b64: null,
  avatar_id: null,
  invite_link: null,
  company_avatar: undefined,
  invite_hash: null,
  is_domain_invite: false,
  is_my_domain_corp: false,
  type: 'LOGIN',
  friends: [],
  domains: [],
  invite_profiles: {},
  allowed_domains: {},
  info_domains: [],
  workspaceUserHash: null,
  referrer: getReferrer()
} as ILoginSlice

const loginSlice: Slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setInitialState: (state: ILoginSlice, action: PayloadAction<string>) => {
      return initialState;
    },
    setMail: (state: ILoginSlice, action: PayloadAction<string>) => {
      state.mail = action.payload
    },
    setWorkspaceUserHash: (state: ILoginSlice, action: PayloadAction<string>) => {
      state.workspaceUserHash = action.payload
    },
    setProfile: (state: ILoginSlice, action: PayloadAction<any>) => {
      state.profile = action.payload
    },
    setInviteProfiles: (state: ILoginSlice, action: PayloadAction<any>) => {
      state.invite_profiles = action.payload
    },
    setHashCode: (state: ILoginSlice, action: PayloadAction<string>) => {
      state.hash_code = action.payload
    },
    setAllowedDomains: (state: ILoginSlice, action: PayloadAction<any>) => {
      state.allowed_domains = action.payload
    },
    setStep: (state: ILoginSlice, action: PayloadAction<steps>) => {
      if (action.payload === 'FINISH') {
        referrerRedirect(REFERRERS.CHATS, state.company_name, getCookie('token') ?? undefined)
        return
      }
      // if (state.referrer && action.payload === 'FINISH') {
      //   if (state.referrer === REFERRERS.CHATS) {
      //     referrerRedirect(REFERRERS.CHATS, state.company_name)
      //     return
      //   }
      // }
      state.step = action.payload
    },
    setError: (state: ILoginSlice, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
    setCompanyName: (state: ILoginSlice, action: PayloadAction<string | undefined>) => {
      state.company_name = action.payload
    },
    setIsLoading: (state: ILoginSlice, action: PayloadAction<boolean>) => {
      state.is_loading = action.payload
    },
    setCompanyDomainStatus: (state: ILoginSlice, action: PayloadAction<boolean | null>) => {
      state.is_domain_free = action.payload
    },
    setBaseAvatar: (state: ILoginSlice, action: PayloadAction<string>) => {
      state.avatar_b64 = action.payload
    },
    setAvatarId: (state: ILoginSlice, action: PayloadAction<number>) => {
      state.avatar_id = action.payload
    },
    setType: (state: ILoginSlice, action: PayloadAction<'REG' | 'LOGIN'>) => {
      state.type = action.payload
    },
    setInviteHash: (state: ILoginSlice, action: PayloadAction<string | null>) => {
      state.invite_hash = action.payload
    },
    setNameSurname: (state: ILoginSlice, action: PayloadAction<{ name: string; surname: string }>) => {
      const { name, surname } = action.payload
      state.name = name
      state.surname = surname
    },
    setIsDomainInvite: (state: ILoginSlice, action: PayloadAction<boolean>) => {
      state.is_domain_invite = action.payload
    },
    setIsMyDomainCorp: (state: ILoginSlice, action: PayloadAction<boolean>) => {
      state.is_my_domain_corp = action.payload
    },
    setDomains: (state: ILoginSlice, action: PayloadAction<string[] | []>) => {
      state.domains = action.payload
    },
    setIsLoadingFriends: (state: ILoginSlice, action: PayloadAction<boolean>) => {
      state.is_loading_friends = action.payload
    },
    setFriends: (state: ILoginSlice, action: PayloadAction<any[]>) => {
      state.friends = action.payload
    },
    setIsDomainLoading: (state: ILoginSlice, action: PayloadAction<boolean>) => {
      state.is_domain_loading = action.payload
    },
    setToken: (state: ILoginSlice, action: PayloadAction<string>) => {
      state.token = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestCodeByEmail.fulfilled, (state, { payload }) => {
      state.is_loading = false
      // Запрос кода по почте
      if (payload.errMsg) {
        Sentry.captureException({
          ...payload,
          key: 'requestCodeByEmail'
        });
        state.error = 'auth.label.limit_requests_exceeded'
        return
      }
      if (payload.response?.send) {
        state.error = null
        state.step = 'CODE'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'requestCodeByEmail',
          send: 'false'
        });
      }
    })
    builder.addCase(confirmCodeByEmail.rejected, (state, action) => {

      state.is_loading = false

      const { errCode, errMsg } = action.payload as {errCode: string, errMsg: string}

      if (errMsg) {
        Sentry.captureException({
          errMsg,
          key: 'confirmCodeByEmail',
          errCode
        });

        if (errCode === CODE_ERRORS.EXPIRED_CODE) {
          state.error = 'auth.label.expired_code';
        } else {
          state.error = 'auth.label.wrong_code';
        }
      }
    })
    builder.addCase(confirmCodeByEmail.fulfilled, (state, { payload }) => {
      debugger
      state.is_loading = false

      if (payload.response) {

        state.error = null

        if (state.mail) {
          const workspaces: any[] = [];
          payload.response.profile.forEach((item: any) => {
            if (item.status === 'ACTIVE') {
              item.email = state.mail;
              delete item.status;
              workspaces.push(item)
            }
          })

          state.profile = workspaces;
        }

        const invited = payload.response.profile.filter((item: any) => {
          return item.status === 'INVITED';
        })

        if (state.mail && invited.length > 0) {
          state.invite_profiles = {[state.mail]: invited}
        }

        state.hash_code = payload.response.hash_code
        if (payload.response.invite.length > 0) {
          if (state.mail) {
            payload.response.invite.forEach((el: any) => {
              el.email = state.mail
            })
            state.allowed_domains = {[state.mail]: payload.response.invite}
          }
          if (state.type === 'INVITE_DOMAIN') {
            const company = payload.response.invite.find((el: any) => {
              return el.domain === state.company_name
            })
            state.invite_hash = company.hash
            state.step = 'COMPANY_BANNER'
            return
          }
        }

        // приглашенный
        if (state.type === 'INVITE_LINK') {
          if (payload.response.invite_create_user && payload.response.invite_create_user === true) {
            state.step = 'PROFILE'
          } else {
            state.step = 'WORKSPACE'
          }
          return
        }
        state.step = 'WORKSPACE'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'confirmCodeByEmail',
          response: 'false'
        });
      }
    })
    builder.addCase(getWorkspaceToken.pending, (state, { payload }) => {
      // Loading экран пока отправляется запрос
      state.step = 'LOADING'
    })
    builder.addCase(getWorkspaceToken.fulfilled, (state, { payload }) => {
      // Получение токена для воркспейсов со статусом 'ACTIVE'
      if (payload.response) {
        // если не прошли заполнение профиля при приглашении, кидаем на профиль
        if (payload.response.invitation) {
          state.type = 'INVITE_MAIL';
          state.step = 'PROFILE';
          return;
        }

        const profile = payload.response.profile;
        if (profile) {
          state.name = profile.name;
          state.surname = profile.surname;
        }

        if (payload.response.token) {
          const profile = payload.response.profile;
          if (profile) {
            setCookieAccountToken({
              token: payload.response.token,
              domain: profile.domain,
              email: profile.email,
              userHash: profile.hash
            })
          }
            referrerRedirect(REFERRERS.CHATS, state.company_name, payload.response.token)
        }
      } else {
        Sentry.captureException({
          ...payload,
          key: 'getWorkspaceToken',
          response: 'false'
        });
        state.step = 'ERROR'
      }

    })
    builder.addCase(getHashcodeInvite.pending, (state, { payload }) => {
      state.step = 'LOADING'
    })
    builder.addCase(getHashcodeInvite.fulfilled, (state, { payload }) => {
      if (payload.response && payload.response.code){
        state.invite_hash = payload.response.code

        state.step = 'COMPANY_BANNER'
        state.type = 'INVITE_MAIL'
        state.company_avatar = payload.response.avatar
      } else {
        Sentry.captureException({
          ...payload,
          key: 'getHashcodeInvite',
          response: 'false'
        });
        state.step = 'ERROR'
      }
    })
    builder.addCase(createNewAccount.fulfilled, (state, { payload }) => {
      // Регистрация нового акка
      if (payload.response) {
        state.error = null;
        state.hash_registration = payload.response.hash_registration
        state.step = 'DOMAIN'
        state.type = 'REG'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'createNewAccount',
          response: 'false'
        });
      }
    })
    builder.addCase(checkDomainIsValid.pending, (state, { payload }) => {
      state.error = null
      state.is_domain_free = null
    })
    builder.addCase(checkDomainIsValid.fulfilled, (state, { payload }) => {
      // Проверка домена на доступность
      state.is_domain_loading = false
      if (payload.errMsg) {
        console.error(payload.errMsg)
        Sentry.captureException({
          ...payload,
          key: 'checkDomainIsValid',
          response: 'false'
        });
        return
      }
      state.is_domain_free = payload.response
    })
    builder.addCase(fillProfileInformation.fulfilled, (state, { payload }) => {
      state.is_loading = false
      // Запрос заполнения профиля
      if (payload.response) {
        state.step = 'INVITE_USERS'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'fillProfileInformation',
          response: 'false'
        });
      }
    })
    builder.addCase(createInviteLink.fulfilled, (state, { payload }) => {
      // Запрос ссылки приглашений
      if (payload.response) {
        state.invite_link = payload.response.link
      } else {
        Sentry.captureException({
          ...payload,
          key: 'createInviteLink',
          response: 'false'
        });
      }
    })
    builder.addCase(inviteUsers.pending, (state, { payload }) => {
      state.step = 'LOADING'
    })
    builder.addCase(inviteUsers.fulfilled, (state, { payload }) => {
      state.is_loading = false
      // Приглашение юзеров через почту
      if (payload.response) {
          referrerRedirect(REFERRERS.CHATS, state.company_name, cookies.get('token'))
      } else {
        Sentry.captureException({
          ...payload,
          key: 'inviteUsers',
          response: 'false'
        });
        state.step = 'ERROR'
      }
    })
    builder.addCase(skipInviteUsers.pending, (state, { payload }) => {
      state.step = 'LOADING'
    })
    builder.addCase(skipInviteUsers.fulfilled, (state, { payload }) => {
      state.is_loading = false
      // Пропуск приглашения юзеров через почту
      if (payload.response) {
        // if (state.referrer === REFERRERS.CHATS) {
          referrerRedirect(REFERRERS.CHATS, state.company_name)
          // return
        // }
        // state.step = 'FINISH'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'skipInviteUsers',
          response: 'false'
        });
        state.step = 'ERROR'
      }
    })
    builder.addCase(inviteLinkInfo.fulfilled, (state, { payload }) => {
      // Проверка инвайта
      if (payload.response) {
        state.company_name = payload.response.domain
        state.company_avatar = payload.response.avatar
        if (payload.response.set_user === true) {
          state.step = 'COMPANY_BANNER'
          state.type = 'INVITE_MAIL'
        } else if (payload.response.set_user === false) {
          state.step = 'COMPANY_BANNER'
          state.type = 'INVITE_LINK'
        }
      } else if (payload.errMsg) {
        Sentry.captureException({
          ...payload,
          key: 'inviteLinkInfo',
        });
        state.step = 'INVITE_INVALID'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'inviteLinkInfo',
          response: 'false'
        });
      }
    })
    builder.addCase(inviteLinkInfo.rejected, (state, { payload }) => {
      state.step = 'INVITE_INVALID'
    })
    builder.addCase(userFalseInviteFillProfile.fulfilled, (state, { payload }) => {
      state.is_loading = false
      if (payload.response.token) {
        const profile = payload.response.profile;
        if (profile) {
          setCookieAccountToken({
            token: payload.response.token,
            domain: profile.domain,
            email: profile.email,
            userHash: profile.hash
          })
        }
        state.token = payload.response.token

        if (state.friends.length > 0) state.step = 'ADD_FRIENDS'
        else {
            referrerRedirect(REFERRERS.CHATS, state.company_name, payload.response.token)
        }
      } else {
        Sentry.captureException({
          ...payload,
          key: 'userFalseInviteFillProfile',
          response: 'false'
        });
      }
    })
    builder.addCase(userTrueInviteFillProfile.fulfilled, (state, { payload }) => {
      state.is_loading = false
      if (payload.response.token) {
        const profile = payload.response.profile;
        if (profile) {
          setCookieAccountToken({
            token: payload.response.token,
            domain: profile.domain,
            email: profile.email,
            userHash: profile.hash
          })
        }
        state.token = payload.response.token
        if (state.friends.length > 0) state.step = 'ADD_FRIENDS'
        else {
            referrerRedirect(REFERRERS.CHATS, state.company_name, payload.response.token)
        }
      } else {
        Sentry.captureException({
          ...payload,
          key: 'userTrueInviteFillProfile',
          response: 'false'
        });
      }
    })
    builder.addCase(sendMessageAndAddToContacts.pending, (state, { payload }) => {
      state.step = 'LOADING'
    })
    builder.addCase(sendMessageAndAddToContacts.fulfilled, (state, { payload }) => {
      if (payload.response) {
        // if (state.referrer === REFERRERS.CHATS) {
          referrerRedirect(REFERRERS.CHATS, state.company_name)
          // return
        // }
        // state.step = 'FINISH'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'sendMessageAndAddToContacts',
          response: 'false'
        });
        state.step = 'ERROR'
      }
    })
    builder.addCase(getPublicDomainInfo.fulfilled, (state, { payload }) => {
      if (payload.errMsg) {
        state.step = 'INVITE_INVALID'
        Sentry.captureException({
          ...payload,
          key: 'getPublicDomainInfo',
          response: 'error'
        });
        return
      }
      if (payload.response) {
        if (payload.response.invite.length > 0) {
          state.company_name = payload.response.domain
          state.company_avatar = payload.response.avatar
          state.info_domains = payload.response.invite
          state.step = 'JOIN_DOMAIN'
        }
      } else {
        Sentry.captureException({
          ...payload,
          key: 'getPublicDomainInfo',
          response: 'false'
        });
      }
    })
  },
})

export const {
  setMail,
  setStep,
  setError,
  setCompanyName,
  setIsLoading,
  setCompanyDomainStatus,
  setBaseAvatar,
  setAvatarId,
  setType,
  setInviteHash,
  setNameSurname,
  setIsDomainInvite,
  setDomains,
  setHashCode,
  setAllowedDomains,
  setInitialState,
  setProfile,
  setInviteProfiles,
  setWorkspaceUserHash,
  setIsMyDomainCorp,
  setIsLoadingFriends,
  setFriends,
  setIsDomainLoading,
  setToken
} = loginSlice.actions

export default loginSlice.reducer
