import { Checkbox } from '@clout-team/web-components'
import React, {useCallback, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDomainInvite, setIsMyDomainCorp } from '../../../state/slices/login'
import "./Check.scss"

interface ICheck {
  corpDomains: string[]
  isMyDomain?: boolean
}

const Check: React.FC<ICheck> = ({ corpDomains, isMyDomain }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isShow, setIsShow] = useState<boolean>(false)
  const dispatch = useDispatch()
  const lang = useSelector((state: any) => state.localization)
  const company = useSelector((state: any) => state.login)
  const L = (key: string) => lang.translations[lang.locale][key] || ''

  const onClickHandler = () => {
    setIsChecked((prev) => !prev)
    if (isMyDomain) dispatch(setIsMyDomainCorp(!isChecked))
    else dispatch(setIsDomainInvite(!isChecked))
  }

  const onCopyHandler = useCallback(() => {
    navigator.clipboard.writeText(`https://${company.company_name}.lenzaos.com`)
    setIsShow(true)
    setTimeout(() => {
      setIsShow(false)
    }, 700)
  }, [isShow, company]);

  const domainsText = L('auth.new.check.domains').split('{__domains__}')

  return corpDomains.length > 0 ? (
    <div className={'ch_check'}>
      <div className={'ch_check__top'}>
        <p className={'ch_check__emoji'}>📫</p>
        <p className={'ch_check__top_description'}>{L('auth.new.invite.banner')}</p>
      </div>
      <div className={'ch_check__bot'}>
        <div className={'ch_check__checkbox'}>
          <Checkbox isChecked={isChecked} onClick={onClickHandler} />
        </div>
        <div className={'ch_check__bot_wrapper'}>
          <p className={'ch_check__bot_label'}>{L('auth.new.invite.checkbox_domains')}</p>
          <p className={'ch_check__bot_description'}>
            {
              domainsText[0]
            }
            {
              corpDomains.map((el, index) => <b key={index}>{'@' + el + ' '}</b>)
            }
            {
              domainsText[1]
            }
            {isChecked && (
              <>
                {/*{' '}{L('auth.new.invite.checkbox_copylink_additional')?.replace('{__link__}', '')}{' '}
                <span onClick={onCopyHandler}>
                  {L('auth.new.invite.checkbox_copylink')}
                  {isShow && <Tooltip className={'ch_check__bot_tooltip'} title={L('auth.new.invite.tooltip')} />}
                </span>*/}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  ) : null
}

export default Check
