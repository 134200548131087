export const BRAND_NAME = 'Lenza'

export const IS_LOCALHOST = window.location.host.includes('localhost:3000');

export const CODE_ERRORS = {
  EXPIRED_CODE: 'LOGIN_0006'
}

export const LANG_ARR = ['en', 'enGB', 'de', 'es', 'esLa', 'fr', 'it', 'pt', 'ru', 'ar', 'ja', 'cnSmpl', 'cn', 'ko', 'hi']

export const REFERRERS = {
  CHATS: 'chat',
  SPACES: 'spaces',
  CONTACTS: 'contacts'
}

export const UI_TYPE = {
  MAC: 'mac',
  WIN: 'win'
}

export const THEME_COOKIE_KEY = 'SELECTED-THEME'
export const COLOR_MODE_COOKIE_KEY = 'SELECTED-COLOR_MODE'

const GLOBAL_CONFIG = {
  domain: 'lenzaos.com'
}

export const THEMES_LIST = [
  {
    id: '7257490c-61ff-4300-b748-d2a2d299906b',
    title: 'White soft',
    slug: 'theme-white-soft',
    is_default: true,
    is_selected: false,
    system: {
      browser: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/browser.png`,
      company: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/company.png`,
      department: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/department.png`,
      project: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/project.png`,
      user: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/user.png`
    },
    chat_type: {
      group: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/`,
      private: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/`,
      local: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/`,
      thread: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_thread.png`,
      topic: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_topic.png`,
      channel: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/`,
      call: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_call.png`,
      bot_calendar: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_bot_calendar.png`,
      bot_task: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_bot_task.png`,
      bot_reminder: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_bot_reminder.png`,
      bot_assistant: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_bot_assistant.png`,
      manager: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_manager.png`,
      bot: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_bot.png`,
      bot_statify: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_bot_statify.png`,
      support: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_support.png`,
      general: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/chat_type_group.png`
    },
    app: {
      blank_page: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/blank_page.png`,
      browser: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/browser.png`,
      biz: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/biz.png`,
      calendar: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/calendar.png`,
      calendar_user: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/calendar_user.png`,
      call: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/call.png`,
      chat: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/chat.png`,
      clients_helper: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/clients_helper.png`,
      clout_assistant: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/clout_assistant.png`,
      clout_preview: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/clout_preview.png`,
      clout_test: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/clout_test.png`,
      guider: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/guider.png`,
      home: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/home.png`,
      personal_manager: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/personal_manager.png`,
      reminder: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/reminder.png`,
      settings: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/settings.png`,
      statistics: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/statistics.png`,
      space: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/space.png`,
      contact: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_soft/app/contact.png`
    }
  },
  {
    id: 'a0c1bc05-bee5-4a7a-95ef-16c6cd987907',
    title: 'White bright',
    slug: 'theme-white-bright',
    is_default: false,
    is_selected: false,
    system: {
      browser: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/browser.png`,
      company: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/company.png`,
      department: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/department.png`,
      project: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/project.png`,
      user: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/user.png`
    },
    chat_type: {
      group: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/`,
      private: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/`,
      local: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/`,
      thread: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_thread.png`,
      topic: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_topic.png`,
      channel: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/`,
      call: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_call.png`,
      bot_calendar: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_bot_calendar.png`,
      bot_task: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_bot_task.png`,
      bot_reminder: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_bot_reminder.png`,
      bot_assistant: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_bot_assistant.png`,
      manager: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_manager.png`,
      bot: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_bot.png`,
      bot_statify: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_bot_statify.png`,
      support: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_support.png`,
      general: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/chat_type_group.png`
    },
    app: {
      blank_page: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/blank_page.png`,
      browser: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/browser.png`,
      biz: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/biz.png`,
      calendar: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/calendar.png`,
      calendar_user: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/calendar_user.png`,
      call: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/call.png`,
      chat: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/chat.png`,
      clients_helper: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/clients_helper.png`,
      clout_assistant: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/clout_assistant.png`,
      clout_preview: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/clout_preview.png`,
      clout_test: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/clout_test.png`,
      guider: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/guider.png`,
      home: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/home.png`,
      personal_manager: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/personal_manager.png`,
      reminder: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/reminder.png`,
      settings: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/settings.png`,
      statistics: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/statistics.png`,
      space: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/space.png`,
      contact: `https://avatars.${GLOBAL_CONFIG.domain}/theme/white_bright/app/contact.png`
    }
  },
  {
    id: 'cd0a4bef-f7a2-43a9-bb4a-338c28be2944',
    title: 'Dark soft',
    slug: 'theme-dark-soft',
    is_default: false,
    is_selected: true,
    system: {
      browser: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/browser.png`,
      company: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/company.png`,
      department: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/department.png`,
      project: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/project.png`,
      user: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/user.png`
    },
    chat_type: {
      group: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/`,
      private: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/`,
      local: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/`,
      thread: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_thread.png`,
      topic: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_topic.png`,
      channel: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/`,
      call: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_call.png`,
      bot_calendar: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_bot_calendar.png`,
      bot_task: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_bot_task.png`,
      bot_reminder: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_bot_reminder.png`,
      bot_assistant: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_bot_assistant.png`,
      manager: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_manager.png`,
      bot: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_bot.png`,
      bot_statify: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_bot_statify.png`,
      support: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_support.png`,
      general: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/chat_type_group.png`
    },
    app: {
      blank_page: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/blank_page.png`,
      browser: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/browser.png`,
      biz: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/biz.png`,
      calendar: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/calendar.png`,
      calendar_user: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/calendar_user.png`,
      call: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/call.png`,
      chat: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/chat.png`,
      clients_helper: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/clients_helper.png`,
      clout_assistant: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/clout_assistant.png`,
      clout_preview: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/clout_preview.png`,
      clout_test: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/clout_test.png`,
      guider: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/guider.png`,
      home: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/home.png`,
      personal_manager: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/personal_manager.png`,
      reminder: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/reminder.png`,
      settings: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/settings.png`,
      statistics: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/statistics.png`,
      space: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/space.png`,
      contact: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_soft/app/contact.png`
    }
  },
  {
    id: 'cc007d15-8c1c-4d96-96d6-6f915eb4a4fc',
    title: 'Dark bright',
    slug: 'theme-dark-bright',
    is_default: false,
    is_selected: false,
    system: {
      browser: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/browser.png`,
      company: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/company.png`,
      department: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/department.png`,
      project: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/project.png`,
      user: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/user.png`
    },
    chat_type: {
      group: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/`,
      private: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/`,
      local: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/`,
      thread: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_thread.png`,
      topic: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_topic.png`,
      channel: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/`,
      call: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_call.png`,
      bot_calendar: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_bot_calendar.png`,
      bot_task: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_bot_task.png`,
      bot_reminder: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_bot_reminder.png`,
      bot_assistant: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_bot_assistant.png`,
      manager: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_manager.png`,
      bot: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_bot.png`,
      bot_statify: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_bot_statify.png`,
      support: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_support.png`,
      general: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/chat_type_group.png`
    },
    app: {
      blank_page: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/blank_page.png`,
      browser: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/browser.png`,
      biz: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/biz.png`,
      calendar: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/calendar.png`,
      calendar_user: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/calendar_user.png`,
      call: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/call.png`,
      chat: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/chat.png`,
      clients_helper: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/clients_helper.png`,
      clout_assistant: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/clout_assistant.png`,
      clout_preview: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/clout_preview.png`,
      clout_test: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/clout_test.png`,
      guider: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/guider.png`,
      home: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/home.png`,
      personal_manager: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/personal_manager.png`,
      reminder: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/reminder.png`,
      settings: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/settings.png`,
      statistics: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/statistics.png`,
      space: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/space.png`,
      contact: `https://avatars.${GLOBAL_CONFIG.domain}/theme/dark_bright/app/contact.png`
    }
  }
];

export const SINGLE_THEMES= [
  // {
  //     name: 'THEME-1',
  //     color: 'rgba(95, 86, 96, 1)',
  //     type: 'colored'
  // },
  // {
  //     name: 'THEME-2',
  //     color:'rgba(255, 2, 33, 1)',
  //     type: 'colored'
  // },
  {
    name: 'PALM',
    // color: { light: 'rgba(102, 96, 209, 1)', dark: '' },
    type: 'colored',
    index: 1,
    key: 'app-theme.1'
  },
  // {
  //     name: 'THEME-4',
  //     color:'#2dbeb5',
  //     type: 'colored'
  // },
  {
    name: 'AUBERGINE',
    // color:'rgba(106, 53, 124, 1)',
    type: 'colored',
    index: 2,
    key: 'app-theme.2'
  },
  // {
  //     name: 'THEME-6',
  //     color:'rgba(40, 57, 133, 1)',
  //     type: 'colored'
  // },
  {
    name: 'SOFT',
    type: 'classic',
    // color: '#EBEBEB',
    index: 3,
    key: 'app-theme.3'
  },
  {
    name: 'BRIGHT',
    type: 'classic',
    // color: '#FFFFFF',
    index: 4,
    key: 'app-theme.4'
  }
]