import React from 'react'
import { useAppSelector } from '../../api/hooks'
import { ILoginSlice, setStep } from '../../state/slices/login'
import './CompanyBanner.scss'
import { ReactComponent as ShieldIcon } from '../../assets/images/shieldIcon.svg'
import Header from '../Header/Header'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@clout-team/web-components'
import { BRAND_NAME } from '../../helpers/constants'
import Privacy from '../Privacy/Privacy'

const CompanyBanner = () => {
  const companyName = useAppSelector((state) => (state.login as ILoginSlice).company_name)
  const dispatch = useDispatch()
  const typeReg = useAppSelector((state) => (state.login as ILoginSlice).type)
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''

  const onClickHandler = () => {
    if (typeReg === 'INVITE_LINK') {
      dispatch(setStep('JOIN_COMPANY'))
    }
    if (typeReg === 'INVITE_MAIL' || typeReg === 'INVITE_DOMAIN') {
      dispatch(setStep('PROFILE'))
    }
  }

  return (
    <div className={'cb_company'}>
      <Header />
      <div className={'cb_company__heading'}>
        <h1 className={'cb_company__title'}>{L('auth.new.company_banner.title_first')}</h1>
        <h1 className={'cb_company__title cb_company__title_company'}>
          {L('auth.new.company_banner.title_second')?.replace('{__name__}!', '')}
          <span>{BRAND_NAME.toUpperCase()}</span>! 🎉
        </h1>
        <p
          className={'cb_company__description'}>{L('auth.banner_description')?.replace('{__company__}', companyName?.toUpperCase() || '')}</p>
      </div>
      <div className={'cb_company__bottom'}>
        <div className={'cb_company__container'}>
          <ShieldIcon className={'cb_company__shield_icon'} />
          <h3 className={'cb_company__bottom_title'}>{L('auth.new.company_banner.container_title_new')}</h3>
          <ul className={'cb_company__list'}>
            <li className={'cb_company__list_item'}>{L('auth.new.company_banner.container_list_first')}</li>
            <li
              className={'cb_company__list_item'}>{L('auth.new.company_banner.container_list_second_new')?.replace('{__company__}', companyName?.toUpperCase() || '')}</li>
            <li className={'cb_company__list_item'}>{L('auth.new.company_banner.container_list_third_new')}</li>
          </ul>
          {/*<p className={'cb_company__bottom_description'}>{L('auth.new.company_banner.container_description')}</p>*/}
        </div>
        <Button
          size={'lg'}
          fullWidth
          onClick={onClickHandler}
        >{L('auth.new.company_banner.button')}</Button>
      </div>
      <Privacy />
    </div>
  )
}

export default CompanyBanner
