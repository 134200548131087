import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'

import login from './slices/login'
import ui from './slices/ui'
import localization from './localization/reducers'

const rootReducer = combineReducers({
  localization,
  login,
  ui,
})

export const configurator = () => {
  return configureStore({
    devTools: true,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        serializableCheck: false,
        immutableCheck: {
          warnAfter: 128,
        },
      }),
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type Store = ReturnType<typeof configurator>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export type Dispatch = Store['dispatch']

export default configurator()
