import { Avatar, BirthdayDate, Button, LangSystem, useAppContext } from '@clout-team/web-components'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fillProfileInformation,
  userFalseInviteFillProfile,
  userTrueInviteFillProfile,
} from '../../api/api'
import { useAppSelector } from '../../api/hooks'
import { readFileAsync, uploadSignupAvatar } from '../../helpers/files'
import { ILoginSlice, setAvatarId, setBaseAvatar, setIsLoading, setNameSurname, setStep } from '../../state/slices/login'
import Heading from '../Heading/Heading'
import Input from '../Input/Input'
import "./Profile.scss"
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

const Profile: React.FC = () => {
  const [nameValue, setNameValue] = useState<string>('')
  const [surnameValue, setSurnameValue] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [date, setDate] = useState<number>(0)
  const avatarB64 = useAppSelector((state) => (state.login as ILoginSlice).avatar_b64)
  const avatarId = useAppSelector((state) => (state.login as ILoginSlice).avatar_id)
  const avatarRef = useRef<null | HTMLInputElement>(null)
  const mail = useAppSelector((state) => (state.login as ILoginSlice).mail)
  const hashReg = useAppSelector((state) => (state.login as ILoginSlice).hash_registration)
  const isLoading = useAppSelector((state) => (state.login as ILoginSlice).is_loading)
  const dispatch = useDispatch()
  const inviteHash = useAppSelector((state) => (state.login as ILoginSlice).invite_hash)
  const logType = useAppSelector((state) => (state.login as ILoginSlice).type)
  const hashCode = useAppSelector((state) => (state.login as ILoginSlice).hash_code)
  const lang = useSelector((state: any) => state.localization)
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false)
  const L = (key: string) => lang.translations[lang.locale][key] || ''

  useEffect(() => {
    const lang = cookies.get('lang')
    LangSystem.changeLang(lang)
  }, [])

  const onNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim()
    setNameValue(val)
  }

  const onSurnameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim()
    setSurnameValue(val)
  }

  const onContinueHandler = () => {
    dispatch(setIsLoading(true));
    if (!isDisabled) {
      const dateValue = new Date(date).toLocaleDateString().replace(/\./g, '-')
      dispatch(setNameSurname({ name: nameValue, surname: surnameValue }))
      const mainData = {
        name: decodeHtmlEntities(nameValue) ,
        surname: decodeHtmlEntities(surnameValue),
        avatar_id: avatarId,
        city_id: '',
        birthday: dateValue,
      }
      if (logType === 'INVITE_LINK' || logType === 'INVITE_DOMAIN') {
        const dataInvite = {
          ...mainData,
          hash_code: hashCode,
          hash: inviteHash,
        }
        dispatch(userFalseInviteFillProfile({ ...dataInvite }))
      } else if (logType === 'INVITE_MAIL') {
        const dataInviteMail = {
          ...mainData,
          hash: inviteHash,
        }
        dispatch(userTrueInviteFillProfile({ ...dataInviteMail }))
      } else {
        const data = {
          ...mainData,
          hash_registration: hashReg,
        }
        dispatch(
          fillProfileInformation({
            ...data,
          }),
        )
      }
    }
  }

  const decodeHtmlEntities = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  const onAvatarChangeHandler = (e: any) => {
    const file = e.target.files[0]
    setIsLoadingAvatar(true)

    readFileAsync(file).then((b64) => {
      if (b64) {
        dispatch(setBaseAvatar(b64))
        if (logType === 'INVITE_LINK' || logType === 'INVITE_MAIL') {
          uploadSignupAvatar(inviteHash as string, file, 'INVITED').then((res) => {
            dispatch(setAvatarId((res as any)?.id))
          }).finally(() => {
            setIsLoadingAvatar(false)
          })
        } else {
          uploadSignupAvatar(hashReg as string, file, 'REG').then((res) => {
            dispatch(setAvatarId((res as any)?.id))
          }).finally(() => {
            setIsLoadingAvatar(false)
          })
        }
      }
    }).catch(() => {
      setIsLoadingAvatar(false)
    })
  }

  // const onBlurNameHandler = (e: any) => {
  //   const val = e.target.value
  //   setNameValue(capitalizeFirstLowercaseRest(val))
  // }
  //
  // const onBlurSurnameHandler = (e: any) => {
  //   const val = e.target.value
  //   setSurnameValue(capitalizeFirstLowercaseRest(val))
  // }

  const onBackHandler = () => {
    if (logType === 'INVITE_MAIL') {
      dispatch(setStep('COMPANY_BANNER'))
    }
  }

  useEffect(() => {
    if (!nameValue || !surnameValue || isLoadingAvatar) {
      setIsDisabled(true)
      return
    }

    setIsDisabled(false)
  }, [nameValue, surnameValue, isLoadingAvatar])

  return (
    <div className={'pr_profile'}>
      <Heading title={L('auth.new.profile.title')} />
      <div className={'pr_profile__wrapper'}>
        <div className={'pr_profile__top'}>
          <div className={'pr_profile__avatar'}>
            <Avatar
              preview={false}
              type='private'
              isFullIconSize
              avatarBg
              upload
              onChange={onAvatarChangeHandler}
              size='72'
              src={avatarB64 === null ? '' : avatarB64}
              inputref={avatarRef}
            />
            <p onClick={() => (avatarRef.current as any).click()} className={'pr_profile__avatar_btn'}>
              {L('auth.new.profile.add_photo')}
            </p>
          </div>
          <div className={'pr_profile__input_wrapper'}>
            <Input
              dismiss={false}
              capitalizeValue={true}
              className={'pr_profile__input'}
              placeholder={L('auth.new.profile.enter_first_name')}
              title={L('auth.new.profile.name')}
              value={nameValue}
              focus
              type={'text'}
              // onBlurHandler={onBlurNameHandler}
              onChange={onNameChangeHandler}
            />
            <Input
              dismiss={false}
              capitalizeValue={true}
              className={'pr_profile__input'}
              placeholder={L('auth.new.profile.enter_last_name')}
              title={L('auth.new.profile.surname')}
              value={surnameValue}
              type={'text'}
              // onBlurHandler={onBlurSurnameHandler}
              onChange={onSurnameChangeHandler}
            />
          </div>
          <div className={'pr_profile__birthday'}>
            <BirthdayDate
              isBirthday={true}
              onChange={(val) => {
                setDate(val)
              }}

              startDate={new Date('1999-01-01 00:00:00').getTime()}
            />
          </div>
        </div>
        <div className={'pr_profile__buttons'}>
          <Button
            size={'lg'}
            loading={isLoading}
            className={'pr_profile__buttons_send'}
            fullWidth
            disabled={isDisabled}
            onClick={onContinueHandler}
          >{L('auth.new.main.continue_btn')}</Button>
          {logType === 'INVITE_MAIL' && <Button
            size={'lg'}
            fullWidth
            color='white'
            onClick={onBackHandler}
          >{L('auth.new.profile.back_btn')}</Button>}
        </div>
        {logType === 'REG' && (
          <>
            <p className={'pr_profile__description'}>
              {L('auth.new.profile.description_first')?.replace('{__mail__}', '')}<b>{mail}</b>
            </p>
            <p className={'pr_profile__description'}>{L('auth.new.profile.description_second')}</p>
          </>
        )}
      </div>
    </div>
  )
}

export default Profile
