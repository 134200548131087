import {Button, Dialog, Divider, Input as InputComponent, Input} from '@clout-team/web-components'
import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { useAppSelector } from '../../api/hooks'
import { ILoginSlice } from '../../state/slices/login'
import "./SendMessageModal.scss"

interface ISendMessageModal {
  onClose: () => void
  onSubmit: (text: string) => void
}

export const SendFirstMessageModal: React.FC<ISendMessageModal> = ({ onClose, onSubmit }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const name = useAppSelector((state) => (state.login as ILoginSlice).name)
  const surname = useAppSelector((state) => (state.login as ILoginSlice).surname)
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''
  const [value, setValue] = useState<string>(L('auth.new.friends.modal.input_default')?.replace('{__name__}', `${name} ${surname}`))

  useEffect(() => {
    if (value.length > 0) setIsDisabled(false)
    else setIsDisabled(true)
  }, [value])

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  const onSubmitHandler = () => {
    onSubmit(value)
  }
  return (
    <Dialog
      onClose={onClose}
      dialogTitle={{
        title: L('auth.new.friends.modal.title'),
      }}
      dialogButtons={{
        right: [
          <Button size='lg' color='white' inverted key='forward-modal-cancel-btn' onClick={onClose}>
            {L('auth.new.cancel')}
          </Button>,
          <Button size='lg' disabled={isDisabled} key='forward-modal-cancel-btn' onClick={onSubmitHandler}>
            {L('auth.new.send')}
          </Button>,
        ],
      }}
    >
      <p className={'modal__description'}>{L('auth.new.friends.modal.description')}</p>
      <Divider direction='vertical' size={21} />
      <p className={'modal__form_title'}>{L('auth.new.friends.modal.input_title')}</p>
      <form>
        <Input focus={true} className={'inp_auth_input'} type='textarea' height='lg' maxLength={256} value={value} onChange={onChangeHandler} />
      </form>
    </Dialog>
  )
}
