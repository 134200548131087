import { CodeInput, Button } from '@clout-team/web-components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {confirmCodeByEmail, IConfirmCode, requestCodeByEmail} from '../../api/api'
import { useAppSelector } from '../../api/hooks'
import { setError, setStep, setIsLoading, ILoginSlice } from '../../state/slices/login'
import Heading from '../Heading/Heading'
import "./CodeStep.scss"

const TIME = 180
let timeOut: ReturnType<typeof setTimeout>

const CodeStep: React.FC = () => {
  const dispatch = useDispatch()
  const email = useAppSelector((state) => state.login.mail)
  const error = useAppSelector((state) => state.login.error)
  const isLoading = useAppSelector((state) => state.login.is_loading)
  const inviteHash = useAppSelector((state) => (state.login as ILoginSlice).invite_hash)
  const [timeState, setTimeState] = useState<number>(TIME)
  const [isClear, setIsClear] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''
  const [code, setCode] = useState<any>('')
  useEffect(() => {
    if (isLoading === false) {
      setIsClear(true)
    }
  }, [isLoading])

  const formatTimer = (seconds: number) => {
    if (seconds > 0) {
      const min = Math.floor(seconds / 60)
      const sec = seconds - min * 60
      let secString = `${sec}`

      if (sec < 10) {
        secString = `0${sec}`
      } else if (sec < 1) {
        secString = `00`
      }

      return `${min}:${secString}`
    } else {
      return ``
    }
  }

  const onClickForRepeatHandler = () => {
    if (timeState === 0) {
      setIsClear(true)
      dispatch(requestCodeByEmail({email: email}))
      setTimeState(TIME)
    }
  }

  const onChangeEmailHandler = () => {
    dispatch(setError(null))
    dispatch(setStep('START'))
  }

  const onCompleteHandler = (value: string) => {
    clearTimeout(timeOut);

    // TODO проверить компонент, дважды отрабатывает, для этого обернул таймаутом
    timeOut = setTimeout(() => {
      dispatch(setIsLoading(true))
      dispatch(setError(null))
      const props: IConfirmCode = { email: email, code: value }
      if (inviteHash) props.hash_invite = inviteHash

      dispatch(confirmCodeByEmail(props))
    }, 100)
  }

  const onChangeHandler = (value: string) => {
    setCode(value)
    setIsClear(false)
    if (value.length === 6) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (timeState > 0) {
      timeout = setTimeout(() => {
        setTimeState((prev) => --prev)
      }, 1000)
    }
    return () => {
      if (timeout !== undefined) {
        clearTimeout(timeout)
      }
    }
  }, [timeState])

  return (
    <div className={'cd_code'}>
      <Heading
        title={`${L('auth.new.codestep.title')}📩`}
        description={L('auth.new.codestep.description')?.replace('{__main__}', email)}
      />
      <div className={'cd_code__bottom'}>
        <p className={'cd_code__bottom_description'}>{L('auth.new.codestep.code_description')}</p>

        <CodeInput
          onValueChange={onChangeHandler}
          clear={isClear}
          type='digits'
          onComplete={onCompleteHandler}
          length={6}
        />

        {error !== null && <p className={'cd_code__error'}>{L(error)}</p>}

        <div className={'cd_code__bottom_buttons'}>
          <Button
            size={'lg'}
            className={'cd_code__resend_btn'}
            color='white'
            disabled={timeState !== 0}
            fullWidth
            onClick={onClickForRepeatHandler}
          >
            {
              timeState !== 0
                ? `${L('auth.new.codestep.send_code_again')} (${formatTimer(timeState)})`
                : `${L('auth.new.codestep.send_code_again')}`
            }
          </Button>
          <p onClick={onChangeEmailHandler} className={'cd_code__link'}>
            {L('auth.button.change_email')}
          </p>
        </div>
        <Button
          loading={isLoading}
          disabled={isDisabled}
          size={'lg'}
          fullWidth
          onClick={() => onCompleteHandler(code)}
        >{L('auth.new.main.continue_btn')}</Button>
      </div>
    </div>
  )
}

export default CodeStep
