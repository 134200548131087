import { Mention } from '@clout-team/icons/react'
import {Loading, Input, Layout, Button} from '@clout-team/web-components'
import React, {useEffect, useMemo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkDomainIsValid, fillCompanyProfile } from '../../api/api'
import { useAppSelector } from '../../api/hooks'
import { ILoginSlice, setCompanyDomainStatus, setCompanyName, setStep, setIsDomainLoading } from '../../state/slices/login'
import Heading from '../Heading/Heading'
import "./Domain.scss"
import { ReactComponent as SuccessSign } from '../../assets/images/successSIgn.svg'
import { ReactComponent as CancelIcon } from '../../assets/images/cancelIcon.svg'
import {isMobile} from "@clout-team/helpers";
let timeOut: ReturnType<typeof setTimeout>
const re = /^[a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]+$/;

const Domain = () => {
  const [value, setValue] = useState<string>('')
  const isDomainLoading = useAppSelector((state) => (state.login as ILoginSlice).is_domain_loading)
  const [debouncedValue, setDebouncedValue] = useState('')
  const dispatch = useDispatch()
  const regHash = useAppSelector((state) => (state.login as ILoginSlice).hash_registration)
  const isDomainFree = useAppSelector((state) => (state.login as ILoginSlice).is_domain_free)
  const [error, setError] = useState<string>('')
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''

  const checkError = (str: string) => {
    if (str.length >= 1 && str.length < 3) {
      setError(L('auth.new.domain.input_count_symbols_error'))
    } else if (str.length >= 3 && !re.test(str)) {
      setError(L('auth.new.domain.input_symbols_error'))
    }
  }

  useEffect(() => {
    clearTimeout(timeOut);
    setError('');
    timeOut = setTimeout(() => {
      if (
        (value.length >= 1 && value.length < 3) ||
        (value.length >= 3 && !re.test(value))
      ) {
        /*if (isMobile()) */checkError(value);
        return;
      }

      setDebouncedValue(value)
    }, 500)

    return () => {
      clearTimeout(timeOut)
    }
  }, [value, isDomainFree])

  useEffect(() => {
    if (isDomainFree === false) {
      setError(L('auth.new.domain.input_error'))
    }
  }, [isDomainFree])

  const onChangeHandler = (e: any) => {
    dispatch(setCompanyDomainStatus(null))
    const val: string = e.target.value.toLocaleLowerCase().trim()
    setValue(val)
  }

  const onContinueHandler = () => {
    if (isDomainFree && !isDomainLoading && value.length > 2) {
      dispatch(
        fillCompanyProfile({
          domain: value,
          hash_registration: regHash,
          lang_id: 'ru',
        }),
      )
      dispatch(setCompanyName(value))
      dispatch(setStep('PROFILE'))
    }
  }

  const onIconClickHandler = () => {
    setValue('')
    dispatch(setCompanyDomainStatus(null))
  }

  useEffect(() => {
    if (debouncedValue) {
      dispatch(setIsDomainLoading(true))
      dispatch(checkDomainIsValid({ hash_registration: regHash, domain: value }))
    }
  }, [debouncedValue])

  const handleBlur = () => {
    checkError(value);
  }

  const renderInputIcon = useMemo(() => {
    if (isDomainLoading) return (
      <div className={'d_loader_wrapper'}>
        <Loading size='sm' isNewDesign className={'d_loader'} />
      </div>
    )
    if (isDomainFree) return <SuccessSign className={'d_success_icon'}/>
    if (!isDomainFree && error) return <CancelIcon onClick={onIconClickHandler} className={'d_cancel_icon'} />
    return <></>;
  }, [error, isDomainFree, isDomainLoading])

  return (
    <div className={'d_domain'}>
      <Heading title={L('auth.new.domain.title')} description={L('auth.new.domain.description')} />
      <div className={'d_domain__wrapper'}>

        <Layout gap={'lg'} vertical={true}>
          <Input
            id={'domain-company'}
            capitalizeValue={false}
            focus
            maxLength={24}
            value={value}
            className={'inp_auth_input'}
            label={L('auth.new.domain.input_title')}
            placeholder={L('auth.new.domain.input_placeholder')}
            // pattern="[a-z0-9\-_]+$"
            description={L('auth.new.domain.input_description')}
            iconLeft={<Mention/>}
            onChange={onChangeHandler}
            iconRight={renderInputIcon}
            onEnterPress={onContinueHandler}
            errorMessage={error}
            validate
            onBlur={handleBlur}
            valid={isDomainFree !== null && isDomainFree}
          />
          <div>
            <p className={'d_domain__subtitle'}>{L('auth.new.domain.input_sec_description')}</p>
            <div className={'d_domain__show_link'}>https://{value !== '' ? value : 'companyname'}.lenzaos.com</div>
          </div>
        </Layout>

        <Button
          size={'lg'}
          disabled={
            error.length > 0 ||
            value.length === 0 ||
            value.length >= 1 && value.length < 3 ||
            value.length >= 3 && !re.test(value)
          }
          fullWidth
          onClick={onContinueHandler}
        >{L('auth.new.company_banner.btn')}</Button>
      </div>
    </div>
  )
}

export default Domain
