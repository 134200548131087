import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestSync, requestWithoutAuth, wrapper } from './fetch'
import { URL } from './urls'
import { RootState } from '../state'
import {
  setFriends,
  setHashCode,
  setInviteHash,
  setIsLoadingFriends,
  setToken,
  setWorkspaceUserHash,
} from '../state/slices/login'
import {
  getDomainFromEmail,
  setCookieAccountToken,
} from '../helpers/functions'
import { Cookies } from 'react-cookie'
import { THEMES_LIST } from '../helpers/constants'

const cookies = new Cookies()

export interface IConfirmCode {
  email: string
  hash_invite?: string
  code: string
}

export const requestCodeByEmail = createAsyncThunk<any, any, any>('login/requestCodeByEmail', async (data) => {
  return await wrapper('post', `${URL.API}/login/code`, {
    email: data.email,
  })
})

export const confirmCodeByEmail = createAsyncThunk<any, any, {
  state: RootState
}>('login/confirmCodeByEmail', async (data, thunkAPI) => {
  const props: IConfirmCode = {
    email: data.email,
    code: data.code,
  }
  if (data.hash_invite) {
    props.hash_invite = data.hash_invite
  }

  const result = await wrapper('put', `${URL.API}/login/code`, props)
  const response = result.response



  if (response.status >= 400) {
    return thunkAPI.rejectWithValue(
      response.data
    )
  }

  const {
    login: { type, workspaceUserHash, profile },
  } = thunkAPI.getState()

  if (
    response &&
    response.profile?.length === 0 &&
    type !== 'INVITE_DOMAIN' &&
    type !== 'INVITE_LINK' &&
    response.invite?.length === 0
  ) {
    thunkAPI.dispatch(setHashCode(response.hash_code))
    thunkAPI.dispatch(createNewAccount({ hash_code: response.hash_code }))

    return { response: undefined }
  }

  // вход в новое рабочее пространство
  if (workspaceUserHash) {
    thunkAPI.dispatch(setHashCode(response.hash_code))
    // если это приглашенный который не заполнил профиль
    const account = profile.find((acc: any) => acc.hashInvite && acc.hash === workspaceUserHash)
    const hashInvite = account?.hashInvite
    if (hashInvite) {
      thunkAPI.dispatch(setInviteHash(hashInvite))
    }

    thunkAPI.dispatch(getWorkspaceToken({ hash_code: response.hash_code, user_hash: workspaceUserHash }))
    thunkAPI.dispatch(setWorkspaceUserHash(null))

    return { response: undefined }
  }

  return result
})

export const getWorkspaceToken = createAsyncThunk<any, any, any>('login/getWorkspaceToken', async (data) => {
  return await wrapper('post', `${URL.API}/login/${data.hash_code}/auth`, {
    user_hash: data.user_hash,
  })
})

export const getHashcodeInvite = createAsyncThunk<any, any, any>('login/getHashcodeInvite', async (data) => {

  // для join из данных кук
  if (!data.hash_registration) {
    const res = data.token ?
      await requestWithoutAuth('post', `${URL.API}/login/invite/${data.hash_code}`, {}, data.token) :
      await wrapper('post', `${URL.API}/login/invite/${data.hash_code}`, {}, cookies.get('token'))
    if (res.response) {
      return {
        response: {
          code: res.response.code,
          avatar: res.response.company?.avatar || '',
        },
      }
    }
    return {}
  } else {
    return await wrapper('post', `${URL.API}/login/${data.hash_registration}/invite/${data.hash_code}`, {})
  }
})

export const checkDomainIsValid = createAsyncThunk<any, any, any>('login/checkDomainIsValid', async (data) => {
  return await wrapper('get', `${URL.API}/login/create/${data.hash_registration}/domain?domain=${data.domain}`, {})
})

export const createNewAccount = createAsyncThunk<any, any, any>('login/createNewAccount', async (data) => {
  // для создания из данных кук
  if (!data.hash_code) {
    return await wrapper('post', `${URL.API}/login/create`, {}, cookies.get('token'))
  } else {
    return await wrapper('post', `${URL.API}/login/${data.hash_code}/create`, { individual: false })
  }
})

export const fillProfileInformation = createAsyncThunk<any, any, any>('login/fillProfileInformation', async (data) => {
  const currentTheme = document.body.className
  const currentThemeId = THEMES_LIST.find(theme => theme.slug === currentTheme)?.id

  return await wrapper('put', `${URL.API}/login/create/${data.hash_registration}/profile`, {
    name: data.name,
    surname: data.surname,
    avatar_id: data.avatar_id,
    city_id: data.city_id,
    birthday: data.birthday,
    theme_id: currentThemeId ?? THEMES_LIST[0].id,
  })
})

export const fillCompanyProfile = createAsyncThunk<any, any, any>('login/fillCompanyProfile', async (data) => {
  return wrapper('put', `${URL.API}/login/create/${data.hash_registration}/company`, {
    domain: data.domain,
    avatar_id: '',
    description: '',
    lang_id: data.lang_id,
  })
})

const regFinish = async (hash: string, thunkAPI: any) => {
  const {
    login: { type, is_domain_invite, is_my_domain_corp, mail, domains },
  } = thunkAPI.getState()

  if (type && type === 'REG') {
    const payload = await wrapper('put', `${URL.API}/login/create/${hash}/finish`, {})
    if (payload.response) {

      thunkAPI.dispatch(setToken(payload.response.token))

      try {
        // отправим метрику - цель
        // @ts-ignore
        ym(93208183, 'reachGoal', 'success_auth')
      } catch (e) {
        console.log('Не удалось отправить цель в метрику')
      }

      const profile = payload.response.profile
      if (profile) {
        setCookieAccountToken({
          token: payload.response.token,
          domain: profile.domain,
          email: profile.email,
          userHash: profile.hash,
        })
      }

      const corpDomains = is_domain_invite ? [...domains] : []
      if (is_my_domain_corp && mail) corpDomains.push(getDomainFromEmail(mail))
      if (corpDomains.length > 0) {
        thunkAPI.dispatch(sendDomainsList({ domains: [...corpDomains], token: payload.response.token }))
      }
    }
  }

}

export const inviteUsers = createAsyncThunk<any, any, any>('login/inviteUsers', async (data, thunkAPI) => {
  const res = await wrapper('put', `${URL.API}/login/create/${data.hash_registration}/invite_user`, {
    emails: [...data.emails],
    skip: data.skip,
  })
  await regFinish(data.hash_registration, thunkAPI)

  return res
})

export const inviteAdmins = createAsyncThunk<any, any, any>('login/inviteAdmins', async (data) => {
  return wrapper('put', `${URL.API}/login/create/${data.hash_registration}/invite_admin?emails=string&skip=true`, {})
})

export const createInviteLink = createAsyncThunk<any, any, any>('login/createInviteLink', async (data) => {
  return wrapper('put', `${URL.API}/login/create/${data.hash_registration}/invite_link`, {})
})

export const skipInviteUsers = createAsyncThunk<any, any, any>('login/skipInviteUsers', async (data, thunkAPI) => {
  const res = await wrapper('put', `${URL.API}/login/create/${data.hash_registration}/invite_user?emails=string&skip=true`, {})

  await regFinish(data.hash_registration, thunkAPI)

  return res
})

export const inviteLinkInfo = createAsyncThunk<any, any, any>('login/inviteLinkInfo', async (data) => {
  return wrapper('get', `${URL.API}/invite/${data.hash}`, {})
})

export const getUsersInCompany = async (token: string) => await requestWithoutAuth('get', `${URL.API}/user`, {}, token)

export const fetchFriendsByToken = createAsyncThunk<any, string>('login/fetchFriendsByToken', async (token, thunkAPI) => {
  const { getState } = thunkAPI

  const state: any = getState()

  const { response } = await requestWithoutAuth('get', `${URL.API}/user`, {}, token)
  if (response && response.item && Array.isArray(response.item)) {
    thunkAPI.dispatch(setIsLoadingFriends(false))

    const users = (response.item).filter((el: any) =>
      el.contact_status === 'NONE' &&
      el.contact_type === 'COMPANY' &&
      el.email !== state.login?.mail && el.status === 'ACTIVE',
    )

    thunkAPI.dispatch(setFriends(users))
  }
})

export const userFalseInviteFillProfile = createAsyncThunk<any, any, any>(
  'login/userFalseInviteFillProfile',
  async (data, thunkAPI) => {
    const res = await wrapper(
      'put',
      `${URL.API}/invite/${data.hash_code}/${data.hash}?name=${data.name}&surname=${data.surname}&avatar_id=${data.avatar_id}&birthday=${data.birthday}&who_see_birthday=ALL&city_id=${data.city_id}`,
      {},
    )
    await thunkAPI.dispatch(fetchFriendsByToken(res?.response?.token))
    return res
  },
)

export const userTrueInviteFillProfile = createAsyncThunk<any, any, any>(
  'login/userTrueInviteFillProfile',
  async (data, thunkAPI) => {
    const res = await wrapper(
      'put',
      `${URL.API}/invite/${data.hash}?name=${data.name}&surname=${data.surname}&avatar_id=${data.avatar_id}&birthday=${data.birthday}&who_see_birthday=ALL&city_id=${data.city_id}`,
      {},
    )
    await thunkAPI.dispatch(fetchFriendsByToken(res?.response?.token))
    return res
  },
)

export const sendMessageAndAddToContacts = createAsyncThunk<any, any, any>(
  'login/sendMessageAndAddToContacts',
  async (data) => {
    return await requestWithoutAuth(
      'post',
      `${URL.API}/user/add`,
      {
        ids: data.ids,
        message: data.message,
      },
      data.token,
    )
  },
)

export const sendDomainsList = createAsyncThunk<any, any, any>('login/sendDomains', async (data) => {
  return await requestWithoutAuth(
    'post',
    `${URL.API}/biz/invite/domain`,
    {
      domains: data.domains,
    },
    data.token,
  )
})

export const getPublicDomainInfo = createAsyncThunk<any, any, any>('login/getPublicDomainInfo', async (data) => {
  return await wrapper('get', `${URL.API}/company/info/${data.name}`, {})
})

export const getLoginListSync = (token = '') => {
  return requestSync('get', `${URL.API}/login/list`, token)
}

export const profileGetSync = (token: string) => {
  return requestSync('get', `${URL.API}/profile.get`, token)
}
