import classNames from 'classnames'
import React from 'react'
import {Input as InputComponent} from "@clout-team/web-components";
import "./Input.scss"

interface IInput {
  id?: string
  value: string
  onChange: (e?: any) => void
  onPaste?: (e?: any) => void
  fullWidth?: boolean
  placeholder?: string
  className?: string
  title?: string
  focus?: boolean
  onBlurHandler?: (e: any) => void
  onKeyDown?: (e?: any) => void
  capitalizeValue?: boolean
  errorMessage?: string
  dismiss?: boolean
  type?: 'text' | 'number' | 'tel' | 'email' | 'password' | 'textarea'
}

const Input: React.FC<IInput> = ({
  id,
  value,
  errorMessage = '',
  onBlurHandler,
  onChange,
  onPaste,
  fullWidth,
  placeholder,
  className,
  title,
  focus,
  onKeyDown,
  type = 'text',
  capitalizeValue = false,
  dismiss = true
}) => {
  const mainStyles = classNames('inp_wrapper', { 'inp_full_width': fullWidth }, className && className)
  return (
    <div className={mainStyles}>
      {title && <p className={'inp_title'}>{title}</p>}
      <InputComponent
        dismiss={dismiss}
        id={id}
        errorMessage={errorMessage}
        type={type}
        capitalizeValue={capitalizeValue}
        className={'inp_auth_input'}
        focus={focus}
        value={value}
        onPaste={onPaste}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onBlur={(e) => {
          if (onBlurHandler) {
            onBlurHandler(e)
          }
        }}
      />
    </div>
  )
}
export default Input
