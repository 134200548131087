import classNames from 'classnames'
import React from 'react'
import "./Heading.scss"

interface IHeading {
  title: string | JSX.Element
  description?: string
}

const Heading: React.FC<IHeading> = ({ title, description }) => {
  return (
    <div className={'hdi_container'}>
      <h2 className={classNames('hdi_title', {'hdi_title_nodescription': !description })}>{title}</h2>
      {description && <p className={'hdi_description'}>{description}</p>}
    </div>
  )
}

export default Heading
