import {createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";

interface IuiSlice {
  os: string
}

const initialState = {
  os: ''
} as IuiSlice

const uiSlice: Slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setOs: (state, {payload}: PayloadAction<string>) => {
      state.os = payload
    },
  }
})

export const {
  setOs
} = uiSlice.actions;

export default uiSlice.reducer;
