import Add from '@clout-team/icons/react/Add'
import People from '@clout-team/icons/react/People'
import ChevronRight from '@clout-team/icons/react/ChevronRight'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createNewAccount,
  getHashcodeInvite,
  getWorkspaceToken,
  inviteLinkInfo,
  requestCodeByEmail,
} from '../../api/api'
import { useAppSelector } from '../../api/hooks'
import { Cookies } from 'react-cookie'
import {
  ILoginSlice,
  Profile,
  setCompanyName,
  setInitialState,
  setInviteHash,
  setWorkspaceUserHash,
  setNameSurname,
  setStep,
  setMail,
  setType,
} from '../../state/slices/login'
import Heading from '../Heading/Heading'
import './Workspace.scss'
import { Button, ListItem } from '@clout-team/web-components'
import { getDomainFromEmail, isInnerIframe, setCookie } from '../../helpers/functions'
import { isMobile } from '@clout-team/helpers'

const cookies = new Cookies()

const Workspace: React.FC = () => {
  const mail: string = useAppSelector((state) => state.login.mail)
  const profiles: Profile[] = useAppSelector((state) => state.login.profile)
  let hash_code: string = useAppSelector((state) => state.login.hash_code)
  const dispatch = useDispatch()
  const inviteProfiles = useAppSelector((state) => (state.login as ILoginSlice).invite_profiles)
  const allowedDomains = useAppSelector((state) => (state.login as ILoginSlice).allowed_domains)
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || '',
    [wsGroup, setWsGroup] = useState<any>({})

  const allowedDomainsTitle = L('auth.new.workspace.domain_anyone').split('{__domain__}')

  const isMobileVersion = isMobile()
  const isIframe = isInnerIframe()

  // формируем группы для аккаунтов
  useEffect(() => {
    const group: any = {}
    profiles.forEach((t: any) => {
      let email = t.email ? t.email : mail
      if (group[email]) {
        group[email].push(t)
      } else {
        group[email] = [t]
      }
    })

    setWsGroup(group)
  }, [profiles, mail])

  const onClickWorkspaceHandler = (
    status: 'ACTIVE' | 'INVITED',
    userHash: string,
    company: string,
    hashInvite?: string,
    email?: string,
  ) => {
    dispatch(setCompanyName(company))
    if (status === 'ACTIVE') {
      if (hash_code) {
        dispatch(getWorkspaceToken({ hash_code: hash_code, user_hash: userHash }))
      } else {
        // запрашиваем код, вход в новое рабочее пространство
        dispatch(setWorkspaceUserHash(userHash))
        // найдем почту для авторизации
        let user: any = profiles.find((p) => p.hash === userHash)
        if (user) {
          dispatch(setMail(user.email))
          dispatch(requestCodeByEmail({ email: user.email }))
        }
      }
    }
    if (status === 'INVITED') {
      if (hashInvite) {
        // перешли по приглашению по емаил
        dispatch(setInviteHash(hashInvite))
        dispatch(inviteLinkInfo({ hash: hashInvite }))
      } else {
        // приглашение по домену
        dispatch(setType('INVITE_DOMAIN'))
        const accounts = Object.keys(allowedDomains)
        if (accounts.length > 0) {
          // ищем в приглашениях по доменам
          if (email) {
            // если аккаунт в приглашениях по домену
            const authTokenUser: any = profiles.find((p: any) => p.email === email && p.token)
            if (authTokenUser) {
              dispatch(
                getHashcodeInvite({
                  hash_registration: hash_code,
                  hash_code: userHash,
                  token: authTokenUser.token,
                  email: email,
                }),
              )
              return
            } else {
              // если join прямо из авторизации
              dispatch(getHashcodeInvite({ hash_registration: hash_code, hash_code: userHash }))
            }
          }
        } else {
          // если join прямо из авторизации
          dispatch(getHashcodeInvite({ hash_registration: hash_code, hash_code: userHash }))
        }
      }
    }
  }

  const onCreateAccountHandler = () => {
    dispatch(createNewAccount({ hash_code: hash_code }))
  }

  const onAddAccountHandler = () => {
    dispatch(setInitialState(''))
    dispatch(setStep('START'))
  }

  const renderInvited = useMemo(
    () => (email: string) => {
      return inviteProfiles[email].map((el: any, i: number) => {
        return (
          <ListItem
            key={el.hash}
            title={el.domain}
            size={'lg'}
            onClick={() => {
              onClickWorkspaceHandler(el.status, el.hash, el.domain, el.hash_invite)
            }}
            avatar={{
              type: 'company',
              src: el.avatar,
            }}
            description={L('auth.new.invited_to_workspace')}
            iconRight={
              <Button
                color={'default'}
                onClick={(e: any) => {
                  e.stopPropagation()
                  onClickWorkspaceHandler(el.status, el.hash, el.domain, el.hash_invite)
                }}
              >
                {L('auth.workspace.join')}
              </Button>
            }
          />
        )
      })
    },
    [L, inviteProfiles],
  )

  const renderAllowed = useMemo(
    () => (email: string) => {
      return allowedDomains[email].map((el: any, i: number) => {
        return (
          <ListItem
            key={el.hash}
            title={el.domain}
            size={'lg'}
            onClick={() => {
              onClickWorkspaceHandler('INVITED', el.hash, el.domain, undefined, el.email)
            }}
            avatar={{
              src: el.avatar,
              type: 'private',
            }}
            description={
              <span className={'ws_workspace__accounts_invite-resume'}>
                {allowedDomainsTitle[0]}
                <span>{'@' + getDomainFromEmail(email)}</span>
                {allowedDomainsTitle[1]}
              </span>
            }
            iconRight={
              <Button
                color={'default'}
                onClick={(e: any) => {
                  e.stopPropagation()
                  onClickWorkspaceHandler('INVITED', el.hash, el.domain, undefined, el.email)
                }}
              >
                {L('auth.workspace.join')}
              </Button>
            }
          />
        )
      })
    },
    [L, allowedDomains, allowedDomainsTitle],
  )

  const renderAccounts = useMemo(
    () => (email: string) => {
      return wsGroup[email].map((el: any) => {
        const copy = { ...el }
        copy.status = 'ACTIVE'

        const clickHandle = (item: any) => {
          if (item.token) {
            setCookie('token', item.token)
            dispatch(setNameSurname({ name: item.name, surname: item.surname ? item.surname : '' }))
            dispatch(setStep('FINISH'))
          } else {
            onClickWorkspaceHandler(item.status, item.hash, item.domain, item.hash_invite)
          }
        }

        return (
          <ListItem
            key={copy.hash}
            title={`${copy.name}${copy.surname ? ' ' + copy.surname : ''}`}
            size={'lg'}
            onClick={() => clickHandle(copy)}
            avatar={{
              src: copy.avatar,
              type: 'private',
            }}
            description={
              copy.status === 'INVITED' ? L('auth.new.invited_to_workspace') : `${copy.login}@${copy.domain}`
            }
            iconRight={
              <Button
                color={'secondary'}
                isStroke={false}
                onClick={(e: any) => {
                  e.stopPropagation()
                  clickHandle(copy)
                }}
              >
                {copy.token ? L('auth.workspace.open') : L('web.header.login')}
              </Button>
            }
          />
        )
      })
    },
    [L, dispatch, wsGroup],
  )

  const renderWrapper = useMemo(
    () => (email: string, children: any) => {
      return (
        <div key={email} className={'ws_workspace__accounts'}>
          <div className={'ws_workspace__accounts_wrapper'}>
            <p className={'ws_workspace__accounts_title'}>
              {L('auth.new.workspace.workspaces_title')?.replace('{__mail__}', '')}
              <span>{email}</span>
            </p>
            {children}
          </div>
        </div>
      )
    },
    [L],
  )

  return (
    <div className={'ws_workspace'}>
      <Heading title={L('auth.new.workspace.title')} />

      {Object.keys(wsGroup).length > 0 ? (
        Object.keys(wsGroup).map((key) => {
          return renderWrapper(
            key,
            <>
              {renderAccounts(key)}
              {Object.keys(inviteProfiles).length > 0 && inviteProfiles[key] && renderInvited(key)}
              {Object.keys(allowedDomains).length > 0 && allowedDomains[key] && renderAllowed(key)}
            </>,
          )
        })
      ) : Object.keys(inviteProfiles).length > 0 && inviteProfiles[mail] ? (
        renderWrapper(
          mail,
          <>
            {renderInvited(mail)}
            {Object.keys(allowedDomains).length > 0 && allowedDomains[mail] && renderAllowed(mail)}
          </>,
        )
      ) : Object.keys(allowedDomains).length > 0 && allowedDomains[mail] ? (
        renderWrapper(mail, renderAllowed(mail))
      ) : (
        <></>
      )}

      <p className={'ws_workspace__text'}>{L('auth.new.or_upper')}</p>

      <div className={'ws_workspace__accounts'}>
        <div className={'ws_workspace__accounts_wrapper ws_workspace__actions'}>
          <ListItem
            className={'ws_workspace__accounts_new_item'}
            icon={<Add />}
            title={L('auth.new.workspace.create_title')}
            description={L('auth.new.workspace.create_description')}
            size={'md'}
            onClick={onCreateAccountHandler}
            iconRight={<ChevronRight />}
          />
          {cookies.get('token') ? (
            <ListItem
              className={'ws_workspace__accounts_new_item'}
              icon={<People />}
              title={L('auth.new.workspace.add_title')}
              description={L('auth.new.workspace.add_description')}
              size={'md'}
              onClick={onAddAccountHandler}
              iconRight={<ChevronRight />}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default Workspace
