import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './App.scss'
import '@clout-team/web-components/build/index.css'
import {BrowserRouter} from "react-router-dom";
import store from './state/index'
import {Provider} from 'react-redux'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import jsonData from "../package.json";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://d1dba62f58cc42179d761b5a47506a7f@error.lenzaos.com/5",
    release: `${jsonData.version}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter basename={"/"}>
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </BrowserRouter>,
)
