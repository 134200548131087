import React from 'react'
import "./Loading.scss"
import { ReactComponent as LenzaIcon } from '../../assets/images/lenzaLogo.svg'
import { useSelector } from 'react-redux'

const Loading: React.FC = () => {
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''
  return (
    <div className={'l_loading'}>
      <LenzaIcon className={'l_loading__icon'} />
      <h2 className={'l_loading__heading'}>{L('auth.new.loading.title')}</h2>
      <p className={'l_loading__description'}>{L('auth.new.loading.description_starting')}</p>
    </div>
  )
}

export default Loading
