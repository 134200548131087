import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLocale } from '../../state/localization/actions'
import "./LanguageSwitch.scss"
import { API, Button, ContextualMenu, Layout, Text, LangSystem } from '@clout-team/web-components'
import ChevronDownFilled from '@clout-team/icons/react/ChevronDownFilled'
import {getCountryImage} from "../../assets/country";
import {ILangList, langList} from "../../hooks/useLangList";


const LanguageSwitch = () => {
  const dispatch = useDispatch()
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''

  const [selectedLang, setSelectedLang] = useState<ILangList | undefined>(langList.find(v => v.reduction === lang.locale)),
    [countryContext, setCountryContext] = useState(null);

  const onLanguageSwitch = (lang: string) => {
    dispatch(setLocale(lang))
    document.documentElement?.setAttribute("lang", lang);
    LangSystem.changeLang(lang)
  }

  return (
    <>
      {countryContext && (
        <ContextualMenu
          isModal
          withWrapper={true}
          alignToElement={countryContext}
          menuList={langList.map((v) => {
            return {
              onClick: () => {
                setSelectedLang(v);
                setCountryContext(null);
                onLanguageSwitch(v.reduction)
              },
              title: v.title.replace('currentName', L(v.currentKeyName)),
              icon: getCountryImage(v.reduction)
            }
          })}
          onClose={() => {
            setCountryContext(null)
          }}
          closeClickedInside={false}/>
      )}
      <Button
        className={'lang-switch'}
        iconRight={<ChevronDownFilled color="grey--80"/>}
        color={'white'}
        inverted
        onClick={(e: any) => {
          setCountryContext(e.currentTarget)
        }}
      >
        <Layout gap={'xsm'} isAlignCenter>
          <div className={'icon'}>
            {selectedLang ? getCountryImage(selectedLang.reduction) : ''}
          </div>
          {selectedLang && <Text>{selectedLang.title.replace('currentName', L(selectedLang.currentKeyName))}</Text>}
        </Layout>
      </Button>
    </>
  )
}

export default LanguageSwitch
