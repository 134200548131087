import { isValidEmail } from '@clout-team/helpers'
import { Avatar, Button } from '@clout-team/web-components'
import React, {useCallback, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestCodeByEmail } from '../../api/api'
import { useAppSelector } from '../../api/hooks'
import { ILoginSlice, setIsLoading, setMail } from '../../state/slices/login'
import Heading from '../Heading/Heading'
import Input from '../Input/Input'
import "./JoinCompany.scss"
import {trimPaste} from "../../helpers/functions";

const JoinCompany: React.FC = () => {
  const [mailValue, setMailValue] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(true)
  const companyName = useAppSelector((state) => (state.login as ILoginSlice).company_name)
  const companyAvatar = useAppSelector((state) => (state.login as ILoginSlice).company_avatar)
  const isLoading = useAppSelector((state) => (state.login as ILoginSlice).is_loading)
  const dispatch = useDispatch()
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setIsError(false)
    setMailValue(value)
    if (isValidEmail(value)) {
      setIsValid(true)
      return
    }
    setIsValid(false)
  }

  const onContinueHandler = () => {
    if (isValidEmail(mailValue) && isValid && !isError) {
      dispatch(setIsLoading(true))
      dispatch(setMail(mailValue))
      dispatch(
        requestCodeByEmail({
          email: mailValue,
        }),
      )
    } else {
      setIsError(true)
    }
  }

  const onBlurHandler = () => {
    if (isValid || mailValue?.trim()?.length === 0) {
      setIsError(false)
      return
    }
    setIsError(true)
  }

  const onKeyDownHandler = (e: any) => {
    if (e.code === 'Enter') {
      onContinueHandler()
    }
  }

  const renderTitle = useCallback(() => {
    const title = L('auth.new.join_comp.title')
    return { __html: title.replace('{__name__}', `<span class="j_company_name">${companyName}</span>`) }
  }, [L, companyName])

  const onPasteHandle = (e: any) => {
    trimPaste(e)
    setTimeout(() => onChangeHandler(e), 100)
  }

  return (
    <div className={'j_join'}>
      <Avatar
        className={'j_join__avatar'}
        preview={false}
        type='company'
        size='72'
        src={companyAvatar ? companyAvatar : ''}
      />
      <Heading
        title={<span dangerouslySetInnerHTML={renderTitle()}/>}
        description={L('auth.new.join_company.description')}
      />
      <div className={'j_join__bottom_wrapper'}>
        <Input
          type={'email'}
          className={'j_join__input'}
          title={L('auth.new.main.email_address')}
          placeholder={L('auth.new.main.enter_email')}
          value={mailValue}
          onChange={onChangeHandler}
          fullWidth
          errorMessage={isError ? L('auth.new.main.error') : ''}
          onBlurHandler={onBlurHandler}
          onKeyDown={onKeyDownHandler}
          onPaste={onPasteHandle}
          focus
        />
        <Button
          size={'lg'}
          loading={isLoading}
          disabled={isError || mailValue === ''}
          fullWidth
          onClick={onContinueHandler}
        >{L('auth.new.main.continue_btn')}</Button>
      </div>
    </div>
  )
}

export default JoinCompany
