import {InviteUsersInCompany, Button} from '@clout-team/web-components'
import classNames from 'classnames'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {createInviteLink, inviteAdmins, inviteUsers, skipInviteUsers} from '../../api/api'
import {useAppSelector} from '../../api/hooks'
import {binarySearch} from '../../helpers/emails'
import {ILoginSlice, setDomains} from '../../state/slices/login'
import Check from './Check/Check'
import CopyLink from './CopyLink/CopyLink'
import "./InviteUsers.scss"
import {getDomainFromEmail} from "../../helpers/functions";

const InviteUsers: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const [validatedItems, setValidatedItems] = useState<number>(0)
  const dispatch = useDispatch()
  const hashReg = useAppSelector((state) => (state.login as ILoginSlice).hash_registration)
  const inviteLink = useAppSelector((state) => (state.login as ILoginSlice).invite_link)
  const email = useAppSelector((state) => (state.login as ILoginSlice).mail)
  const isMyDomainCorp = useAppSelector((state) => (state.login as ILoginSlice).is_my_domain_corp)
  const isDomainInvite = useAppSelector((state) => (state.login as ILoginSlice).is_domain_invite)
  const [corpDomains, setCorpDomains] = useState<string[] | []>([])
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''

  const isValidate = (el: any) => el.isValid && !el.isExists;

  const onSendHandler = () => {
    if (isSuccess) {
      dispatch(inviteAdmins({skip: true, hash_registration: hashReg}))
      const validItems = items.map((el) => {
        if (isValidate(el)) {
          return el.title
        }
        return
      })
      dispatch(setDomains([...corpDomains]))
      dispatch(inviteUsers({emails: [...validItems], skip: false, hash_registration: hashReg}))
      return
    }
    if (!isSuccess && validatedItems > 0) {
      const validItems = items.map((el) => {
        if (isValidate(el)) {
          return getDomainFromEmail(el.title)
        }
        return
      })

      const uniqueItems = validItems
        .filter((element, index) => {
          return validItems.indexOf(element) === index
        })
        .filter((el) => {
          const res = binarySearch(el)
          return res === -1 && el !== undefined
        })

      setCorpDomains(uniqueItems)
      setIsSuccess(true)
    }
  }

  const isCorpEmail = (email: string) => {
    const res = binarySearch(getDomainFromEmail(email))
    return res === -1 && email !== undefined
  }

  const onSkipHandler = () => {
    if (isSuccess) {
      setIsSuccess(false)
      return
    }
    if (!isSuccess) {
      dispatch(inviteAdmins({skip: true, hash_registration: hashReg}))
      dispatch(skipInviteUsers({hash_registration: hashReg}))
    }
  }

  useEffect(() => {
    dispatch(createInviteLink({hash_registration: hashReg}))
  }, [])

  return (
    <div className={'inu_invite'}>
      <h2 className={'inu_invite__heading'}>
        {isSuccess
          ? `${L('auth.new.invite_users.your_invitation')?.replace('{__items__}', validatedItems)}`
          : `${L('auth.new.invite.title')}${validatedItems > 0 ? ' ' + validatedItems : ''}`}
      </h2>
      {isSuccess && (
        <p className={'inu_invite__description'}>
          {L('auth.description.you_invited')?.replace('{__members__}', ` ${validatedItems} ${L('auth.description.members')}`)}
        </p>
      )}
      <div className={classNames('inu_invite__wrapper', {'inu_invite__wrapper_success': isSuccess})}>

        <InviteUsersInCompany
          isUserInvite={false}
          isSuccess={isSuccess}
          onChangeValidItems={(count) => {
            setValidatedItems(count)
          }}
          onChangeItems={(val) => setItems(val)}
          hashRegistration={hashReg}
        />

        {!isSuccess ? (
          <>
            <CopyLink copyTarget={inviteLink as string}/>
            {
              email && isCorpEmail(email) ? (
                <div className={'inu_invite__check_wrapper'}>
                  <Check isMyDomain={true} corpDomains={[getDomainFromEmail(email)]}/>
                </div>
              ) : <></>
            }
          </>
        ) : (
          <div className={'inu_invite__check_wrapper'}>
            <Check corpDomains={corpDomains}/>
          </div>
        )}
      </div>
      <div className={'inu_invite__buttons'}>
        {
          isMyDomainCorp && validatedItems === 0 ? (
            <Button
              size={'lg'}
              className={'inu_invite__buttons_send'}
              fullWidth
              onClick={onSkipHandler}
            >{L('auth.new.done')}</Button>
          ) : (
            <Button
              size={'lg'}
              disabled={validatedItems === 0}
              className={'inu_invite__buttons_send'}
              fullWidth
              onClick={onSendHandler}
            >{isSuccess ? L('auth.new.done') : L('auth.new.send')}</Button>
          )
        }

        {
          isDomainInvite ? <></> : (
            <Button
              size={'lg'}
              color={'white'}
              inverted={!isSuccess}
              fullWidth
              onClick={onSkipHandler}
            >{isSuccess ? L('auth.new.invite_more') : L('auth.new.invite_later')}</Button>
          )
        }
      </div>
    </div>
  )
}

export default InviteUsers
