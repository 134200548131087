import React, {useCallback, useEffect, useState} from 'react'
import Domain from '../Domain/Domain'
import Header from '../Header/Header'
import Loading from '../Loading/Loading'
import CodeStep from '../CodeStep/CodeStep'
import Workspace from '../Workspace/Workspace'
import Login from '../Login/Login'
import './Main.scss';
import Finish from '../Finish/Finish'
import Profile from '../Profile/Profile'
import InviteUsers from '../InviteUsers/InviteUsers'
import JoinCompany from '../JoinCompany/JoinCompany'
import InviteInvalid from '../InviteInvalid/InviteInvalid'
import { useParams, Navigate } from "react-router-dom";
import { useAppSelector } from '../../api/hooks'
import {
  setInviteHash,
  setNameSurname,
  setStep,
  setType,
  setProfile,
  steps,
  setCompanyName, setInitialState, setMail, setWorkspaceUserHash, setInviteProfiles, setAllowedDomains
} from '../../state/slices/login'
import {useDispatch, useSelector} from 'react-redux'
import {
  createNewAccount, fetchFriendsByToken,
  getHashcodeInvite, getLoginListSync,
  getPublicDomainInfo, getUsersInCompany,
  inviteLinkInfo,
  requestCodeByEmail,
} from '../../api/api'
import CompanyBanner from '../CompanyBanner/CompanyBanner'
import AddFriends from '../AddFriends/AddFriends'
import JoinDomain from '../JoinDomain/JoinDomain'
import {
  changeLangUrl,
  checkToken,
  getAuthEmailFromWorkspaces, getCookieAccounts,
  getCookieLang,
  getCurrentUserFromWorkspaces,
  getInviteDomainFromCookie,
  getInviteFromCookie, initColorMode, initTheme,
} from '../../helpers/functions'
import {BRAND_NAME, LANG_ARR, UI_TYPE} from "../../helpers/constants";
import classNames from "classnames";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import { ErrorPage, config, getSystemTheme } from '@clout-team/web-components'
import { getCookie, isMobile } from '@clout-team/helpers'
import { IProfile } from '@clout-team/web-components/build/config/contacts/Contact'

declare global {
  interface Window {
    theme?: string
    appAddAccount?: boolean // если новый аккаунт из электрона
    appAddMobile?: boolean // если новый аккаунт из мобильных приложений
  }
}



const Main: React.FC = () => {
  const state = useAppSelector((state) => state.login.step)
  const os = useAppSelector((state) => state.ui.os)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const dispatch = useDispatch(),
    { path, locale } = useParams();

  const lang = useSelector((state: any) => state.localization)
  const L = useCallback((key: string) => lang.translations[lang.locale][key],[lang])

  useEffect(() => {
    document.title = `${BRAND_NAME} - ${L('auth.title.authorization')}`
  }, [L])

  useEffect(() => {
    const queryString = window.location.search
    const host = window.location.host.replace('.lenzaos.com', '')
    const urlParams = new URLSearchParams(queryString)

    // получим список аккаунтов
    const workspaces: any = [], invited: any = {}, allowed: any = {};

    if (checkToken()) {
      const accounts = getCookieAccounts();
      if (accounts.length > 0) {
        accounts.forEach((user: any) => {
          const token = user.token;
          if (!invited[user.email]) invited[user.email] = [];
          if (!allowed[user.email]) allowed[user.email] = [];

          if (token) {
            // @ts-ignore
            const list: any = getLoginListSync(token);
            if (list && list.response) {
              list.response?.profile?.forEach((p: any) => {
                if (p.status.toLowerCase() === 'active') {
                  const obj = {...p, email: user.email};
                  
                  const authorizedWorkspace = accounts.find((account: any) => account.hash === obj.hash)
                  if (authorizedWorkspace) {
                    obj.token = authorizedWorkspace.token
                  }

                  if (!workspaces.find((workspace: any) => workspace.hash === obj?.hash)) {
                    workspaces.push(obj)
                  }

                } else {
                  invited[user.email].push(p)
                }
              })
              list.response?.invite?.forEach((allow: any) => {
                allowed[user.email].push({...allow, email: user.email})
              })
            }
          }

          if (invited[user.email].length === 0) delete invited[user.email];
          if (allowed[user.email].length === 0) delete allowed[user.email];
        })

        dispatch(setProfile(workspaces))
        dispatch(setInviteProfiles(invited))
        dispatch(setAllowedDomains(allowed))
      }
    }

    if (urlParams.get('email') !== null) {
      const code = urlParams.get('email')
      dispatch(setMail(code))
    }

    if (urlParams.get('code') !== null) {
      const code = urlParams.get('code')
      dispatch(setInviteHash(code))
      dispatch(inviteLinkInfo({ hash: code }))
      return
    }

    if (host !== 'auth' && host !== 'dev-auth' && host !== 'localhost:3000') {
      dispatch(getPublicDomainInfo({ name: host }))
      dispatch(setType('INVITE_DOMAIN'))
      return
    }

    if (path && (path === 'open' || path === 'join' || path === 'join-domain' || path === 'add' || path === 'create') || checkToken()) {
      dispatch(setMail(getAuthEmailFromWorkspaces(workspaces)))
    }

    if (path && path === 'open') {
      const user= getCurrentUserFromWorkspaces(workspaces);
      if (user) {
        dispatch(setNameSurname({
          name: user.name,
          surname: user.surname
        }))

        if (user.token) {
          dispatch(setStep('FINISH'))
        } else {
          // запрашиваем код, вход в новое рабочее пространство
          dispatch(setWorkspaceUserHash(user.hash));
          dispatch(requestCodeByEmail({email: user.email}))
        }
      }
    } else if (path && path === 'join') {
        const invite = getInviteFromCookie(invited);
        if (invite) {
          dispatch(setCompanyName(invite.domain))
          dispatch(setInviteHash(invite.hash_invite))
          dispatch(inviteLinkInfo({hash: invite.hash_invite}))
        } else dispatch(setStep('INVITE_INVALID'))
    } else if (path && path === 'join-domain') {
      const domainInvite = getInviteDomainFromCookie(allowed);
      if (domainInvite) {
        dispatch(setCompanyName(domainInvite.domain))
        dispatch(setType('INVITE_DOMAIN'))
        const authTokenUser = workspaces.find((p: any) => p.email === domainInvite.email && p.token);
        if (authTokenUser) {
          dispatch(getHashcodeInvite({
            hash_registration: undefined,
            hash_code: domainInvite.hash,
            token: authTokenUser.token,
            email: domainInvite.email
          }))
          return;
        }
        dispatch(getHashcodeInvite({hash_registration: undefined, hash_code: domainInvite.hash, email: domainInvite.email}))
      } else dispatch(setStep('INVITE_INVALID'))
    } else if (path && path === 'add') {
      dispatch(setInitialState(''))
      dispatch(setStep('START'))
    } else if (path && path === 'create') {
      dispatch(createNewAccount({hash_code: undefined}))
    } else {
      if (checkToken()) {
        if (workspaces.length === 0) {
          dispatch(setStep('START'))
        } else {
          dispatch(setStep('WORKSPACE'))
        }
      } else {
        dispatch(setStep('START'))
      }
    }

    if (isElectron()) {
      const handle = (data: boolean) => setIsFullScreen(data)
      // @ts-ignore
      window?.ipcRenderer?.on('set-is-full-screen', handle);
      return () => {
        // @ts-ignore
        window?.ipcRenderer?.removeListener('set-is-full-screen', handle);
      }
    }
  }, [])


  if (!locale || LANG_ARR.indexOf(locale) === -1) {
    return <InviteInvalid />
  } else if (getCookieLang() !== locale) {
    return <Navigate to={changeLangUrl(locale)} />
  }

  const currentState =  (sliceState: steps) => {
    switch (sliceState) {
      case 'START':
        return <Login />
      case 'CODE':
        return <CodeStep />
      case 'WORKSPACE':
        return <Workspace />
      case 'PROFILE':
        return <Profile />
      case 'FINISH':
        return <Finish />
      case 'DOMAIN':
        return <Domain />
      case 'LOADING':
        return <Loading />
      case 'INVITE_USERS':
        return <InviteUsers />
      case 'JOIN_COMPANY':
        return <JoinCompany />
      case 'INVITE_INVALID':
        return <InviteInvalid />
      case 'COMPANY_BANNER':
        return <CompanyBanner />
      case 'ADD_FRIENDS':
        return <AddFriends />
      case 'JOIN_DOMAIN':
        return <JoinDomain />
      case 'ERROR':
        return <ErrorPage />
    }
  }

  return (
    <div className={classNames('p_main', {
      'is-electron-auth': isElectron(),
      'is-full-screen': isFullScreen,
      'is-electron-auth-mac': isElectron() && os === UI_TYPE.MAC,
      'is-electron-auth-win': isElectron() && os === UI_TYPE.WIN
    })}>
      {state === 'INVITE_INVALID' || state === 'COMPANY_BANNER' ? null : <Header />}
      <>{currentState(state)}</>
    </div>
  )
}

export default Main
