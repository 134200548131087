import React, { useEffect, useState } from 'react'
import './CardContact.scss'
import {Avatar, Checkbox, Tag, Text} from '@clout-team/web-components'
import classNames from 'classnames'
import {useSelector} from "react-redux";
import {CheckmarkCircleFilled} from "@clout-team/icons/react";

const CardContact = (props: {
  isSelected?: boolean
  title: string
  description: string
  avatar?: string
  onClick?: (e: any) => void
  isFriend?: boolean
}) => {
  const { isSelected = false, title, description, avatar = '', onClick = () => {}, isFriend = false } = props
  const [selected, setSelected] = useState<boolean>(isSelected)

  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''

  useEffect(() => {
    setSelected(isSelected)
  }, [isSelected])

  const clickHandle = (e: any) => {
    onClick(e)
  }

  return (
    <div
      className={classNames('card-contact-component', {
        'card-contact-selected': selected,
        'card-contact-friend': isFriend,
      })}
    >
      <div className='card-contact-avatar' onClick={clickHandle}>
        <Avatar className='card-contact-avatar-component' size='full' type='private' src={avatar} />
        {
          isFriend ? (
            <Tag icon={<CheckmarkCircleFilled/>} className={'card-contact-tag'}>{L('auth.already_added')}</Tag>
          ) : <Checkbox className='card-contact-checkbox' isChecked={selected} onClick={clickHandle}/>
        }
      </div>
      <div className='card-contact-content'>
        <div className='card-contact-title'>
          <Text type='body-bold'>{title}</Text>
        </div>
        <div className='card-contact-position'>
          <Text type='secondary' size='footnote'>
            {description}
          </Text>
        </div>
      </div>
    </div>
  )
}

export default CardContact
