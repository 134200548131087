import Copy from '@clout-team/icons/react/Copy'
import Link from '@clout-team/icons/react/Link'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import "./CopyLink.scss"

const CopyLink: React.FC<{ copyTarget: string }> = ({ copyTarget }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [unmounting, setUnmounting] = useState<boolean>(false)
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''

  const onCopyHandler = () => {
    navigator.clipboard.writeText(copyTarget)
    setIsVisible(true)
    setTimeout(() => {
      setUnmounting(true)
    }, 2500)
    setTimeout(() => {
      setIsVisible(false)
      setUnmounting(false)
    }, 3000)
  }

  return (
    <>
      <p onClick={onCopyHandler} className={'cp_copy'}>
        <Link className={'cp_copy__icon'} />
        {L('auth.new.invite.copy_link')}
      </p>
      {isVisible && (
        <div className={classNames('cp_popup', { 'cp_popup_unmount': unmounting })}>
          <Copy className={'cp_popup__icon'} />
          {L('auth.new.invite.copied_message')}
        </div>
      )}
    </>
  )
}

export default CopyLink
