import {Avatar, Button, Select} from '@clout-team/web-components'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestCodeByEmail } from '../../api/api'
import { useAppSelector } from '../../api/hooks'
import { ILoginSlice, setIsLoading, setMail } from '../../state/slices/login'
import Heading from '../Heading/Heading'
import Input from '../Input/Input'
import "./JoinDomain.scss"
import {trimPaste} from "../../helpers/functions";

const JoinDomain: React.FC = () => {
  const [mailValue, setMailValue] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(true)
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''
  const companyName = useAppSelector((state) => (state.login as ILoginSlice).company_name)
  const companyAvatar = useAppSelector((state) => (state.login as ILoginSlice).company_avatar)
  const domains = useAppSelector((state) => (state.login as ILoginSlice).info_domains)
  const isLoading = useAppSelector((state) => (state.login as ILoginSlice).is_loading)
  const dispatch = useDispatch()
  const [mailDomain, setMailDomain] = useState<string>(domains.length > 0 ? '@' + domains[0].domain : '')

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setIsError(false)
    setMailValue(value)
  }

  const onContinueHandler = () => {
    dispatch(setIsLoading(true))
    if (isValid && !isError) {
      const fullMail = mailValue + mailDomain
      dispatch(setMail(fullMail))
      dispatch(requestCodeByEmail({ email: fullMail }))
    }
  }

  const onBlurHandler = () => {
    if (isValid) {
      setIsError(false)
      return
    }
    setIsError(true)
  }

  const onSelectHandler = (value: any) => {
    setMailDomain(value.title)
  }

  const onPasteHandle = (e: any) => {
    trimPaste(e)
    setTimeout(() => onChangeHandler(e), 100)
  }

  return (
    <div className={'jd_join'}>
      <Avatar
        className={'jd_join__avatar'}
        preview={false}
        type='company'
        size='72'
        src={companyAvatar ? companyAvatar : ''}
      />
      <Heading
        title={
          <>
            {L('auth.new.join_comp.title')?.replace(
              '{__name__}',
              <span className={'jd_company_name'}>{companyName}</span>,
            )}
          </>
        }
        description={L('auth.new.join_company.description')}
      />
      <div className={'jd_join__bottom_wrapper'}>
        <div className={'jd_join__input_wrapper'}>
          <Input
            type={'email'}
            className={'jd_join__input'}
            title={L('auth.new.main.email_address')}
            placeholder={L('auth.new.join_domain.work_address')}
            value={mailValue}
            onChange={onChangeHandler}
            errorMessage={isError ? L('auth.new.main.error') : ''}
            onBlurHandler={onBlurHandler}
            onPaste={onPasteHandle}
            focus
          />
          <Select
            value={{ title: '@' + domains[0]?.domain, id: 0 }}
            options={[
              ...domains.map((el, index) => {
                return { title: '@' + el.domain, id: index }
              }),
            ]}
            onChange={onSelectHandler}
          />
        </div>
        <div className={'jd_join__container'}>
          <p className={'jd_join__container_heading'}>{L('auth.new.join_domain.any_account')}</p>
          <ul className={'jd_join__container_list'}>
            {domains.map((el) => {
              return <li className={'jd_join__container_list_element'}>{el.domain}</li>
            })}
          </ul>
          <p className={'jd_join__container_description'}>{L('auth.new.join_domain.dont_have_email')}</p>
          <p className={'jd_join__container_description'}>
            {L('auth.new.join_domain.contact_admin')?.replace('{__name__}', <span>{companyName}</span>)}
          </p>
        </div>
        <Button
          loading={isLoading}
          size={'lg'}
          disabled={isError || mailValue === ''}
          fullWidth
          onClick={onContinueHandler}
        >{L('auth.new.main.continue_btn')}</Button>
      </div>
    </div>
  )
}

export default JoinDomain
