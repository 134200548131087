import {Button, Checkbox} from '@clout-team/web-components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendMessageAndAddToContacts, userTrueInviteFillProfile } from '../../api/api'
import { useAppSelector } from '../../api/hooks'
import { IFriend, ILoginSlice, setStep } from '../../state/slices/login'
import CardContact from '../CardContact/CardContact'
import { SendFirstMessageModal } from '../SendMessageModal/SendMessageModal'
import "./AddFriends.scss";

interface IContacts extends IFriend {
  chosen?: boolean
}

const AddFriends: React.FC = () => {
  const dispatch = useDispatch()
  const token = useAppSelector((state) => (state.login as ILoginSlice).token)
  const friends: IFriend[] | [] = useAppSelector((state) => (state.login as ILoginSlice).friends)
  const login = useAppSelector((state) => (state.login as ILoginSlice))
  const [contacts, setContacts] = useState<IContacts[] | []>(friends)
  const [valids, setValids] = useState<number>(0)
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const isLoadingFriends: boolean = useAppSelector((state) => (state.login as ILoginSlice).is_loading_friends)
  const lang = useSelector((state: any) => state.localization)


  const L = (key: string) => lang.translations[lang.locale][key] || ''

  const onSkipHandler = () => {
    dispatch(setStep('FINISH'))
  }

  const onAddContactsHandler = (text: string) => {
    if (valids !== 0) {
      const arr = contacts
        .filter((el) => {
          if (el.chosen) {
            return el
          }
        })
        .map((el) => {
          return el.hash
        })
      dispatch(sendMessageAndAddToContacts({ ids: arr, message: text, token: token }))
    }
  }

  const onCardClickHandler = (el: IContacts) => {
    if (el.is_friend) return;
    setContacts((prev) => [
      ...prev.map((contact: IContacts) => {
        if (allSelected) {
          setAllSelected(false)
        }
        if (contact.hash === el.hash) {
          return { ...contact, chosen: !contact.chosen }
        }
        return contact
      }),
    ])
    setIsChanged((prev) => !prev)
  }

  const onChoseAllHandler = () => {
    if (allSelected) {
      setContacts((prev) => [
        ...prev.map((el) => {
          return {
            ...el,
            chosen: false,
          }
        }),
      ])
      setValids(0)
      setAllSelected(false)
      return
    } else {
      setContacts((prev) => [
        ...prev.map((el) => {
          return {
            ...el,
            chosen: !el.is_friend,
          }
        }),
      ])
      setValids(contacts.length)
      setAllSelected(true)
    }
    setIsChanged((prev) => !prev)
  }

  useEffect(() => {
    let num: number = 0
    setValids(0)
    contacts.map((el) => {
      if (el.chosen === true) {
        num++
      }
    })
    setValids(num)
    if (num > 0 && num === contacts.length && !isLoadingFriends) {
      setAllSelected(true)
    }
  }, [isChanged])


  useEffect(() => {
      setContacts(friends)
  }, [friends])

  return (
    <>
      <div className={'addf_friends'}>
        <div className={'addf_friends__heading'}>
          <h2 className={'addf_friends__title'}>{L('auth.new.friends.title')}</h2>
          <p className={'addf_friends__description'}>{L('auth.new.friends.description')}</p>
        </div>
        <div className={'addf_friends__wrapper'}>
          {contacts.map((el: IContacts) => {
            return (
              <CardContact
                key={el.hash}
                onClick={() => onCardClickHandler(el)}
                isSelected={el.chosen}
                title={el.name + ' ' + el.surname}
                avatar={el.avatar}
                isFriend={el.is_friend}
                description={el.department ? el.department.title : el.domain}
              />
            )
          })}
        </div>
        <div className={'addf_friends__footer'}>
          <div className={'addf_friends__checkbox_wrapper'}>
            <Checkbox onClick={onChoseAllHandler} isChecked={allSelected} title={L('auth.new.friends.select_all')}/>
          </div>
          <Button
            size={'lg'}
            className={'addf_friends__button_skip'}
            color='white'
            onClick={onSkipHandler}
          >{L('auth.new.friends.skip')}</Button>
          <Button
            size={'lg'}
            className={'addf_friends__button_add'}
            disabled={valids === 0}
            onClick={() => setIsOpen((prev) => !prev)}
          >{L('auth.new.friends.add_to_contacts')?.replace('{__num__}', `${valids > 0 ? `(${valids})` : ''}`)}</Button>
        </div>
      </div>
      {isOpen && (
        <SendFirstMessageModal
          onClose={() => setIsOpen((prev) => !prev)}
          onSubmit={(text) => onAddContactsHandler(text)}
        />
      )}
    </>
  )
}

export default AddFriends
