import React, {useMemo} from 'react'
import "./Header.scss"
import { ReactComponent as LenzaIcon } from '../../assets/images/lenzaLogo.svg'
import {BRAND_NAME, UI_TYPE} from "../../helpers/constants";
import {useSelector} from "react-redux";
import {Layout, TrafficLights} from "@clout-team/web-components";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import {sendToElectron} from "@clout-team/web-components";
import {useAppSelector} from "../../api/hooks";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";


const Header: React.FC = () => {
  const lang = useSelector((state: any) => state.localization)
  const L = (key: string) => lang.translations[lang.locale][key] || ''
  let os = useAppSelector((state) => state.ui.os)

  const renderTrafficLight = useMemo(() => {
    const onClose = () => sendToElectron('close-window')
    const onMinimize = () => sendToElectron('to-tray-window')
    const onMaximize = () => sendToElectron('max-window')

    return <TrafficLights onClose={onClose} onMinimize={onMinimize} onMaximize={onMaximize}
                          isWin={true}/>
  }, [])

  return (
    <div className={'hd_header'}>
      <Layout gap={'sm'} isAlignCenter className={'hd_header__logo'}>
        <LenzaIcon className={'hd_header__icon'} />
        <h1 className={'hd_header__heading'}>
          {BRAND_NAME} <span>{L('auth.title.authorization')}</span>
        </h1>
      </Layout>
      <LanguageSwitch/>
      {
        isElectron() && os === UI_TYPE.WIN ? renderTrafficLight : <></>
      }
    </div>
  )
}

export default Header
